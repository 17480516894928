<template>
  <div class="main-sidebar">
    <div class="sidebar__header mb-6">
      <Transition name="fade">
        <div
          v-if="!sidebarStore.isCompact"
          class="flex items-center gap-2 cursor-pointer"
          @click="$router.push({ name: 'start' })">
          <img
            src="/logo/logo-img.png"
            width="38"
            height="38" />

          <div class="sidebar__header_logo-text" />
        </div>
      </Transition>

      <AppButton
        class="sidebar__header_collapse-btn"
        type="primary"
        icon-left="icons/arrow-right-stop"
        :icon-left-rotate="!sidebarStore.isCompact ? '180deg' : '0deg'"
        @click="sidebarStore.isCompact = !sidebarStore.isCompact" />
    </div>

    <RouterLink
      :to="{ name: 'villages' }"
      :class="{ 'sidebar__menu-item--active': $route.name === 'villages' }"
      class="sidebar__menu-item mb-3">
      <div class="sidebar__menu-item_border" />

      <AppIcon
        class="sidebar__menu-item_icon"
        size="20px"
        :name="`icons/home`" />

      <div class="sidebar__menu-item_title">Главная</div>
    </RouterLink>

    <AppDropdown
      placement="bottom"
      custom-class="sidebar__menu-dropdown"
      transition-name="transition-slide"
      :hover-delay="100"
      :offset="sidebarStore.isCompact ? [99, -40] : [177, -40]">
      <template #default="{ isActive }">
        <div
          class="sidebar__app-category"
          :class="{ 'sidebar__app-category--active': isActive }">
          <Transition
            name="slide"
            mode="out-in">
            <div
              v-if="sidebarStore.isCompact"
              class="sidebar__app-category_burger">
              <AppIcon
                class="sidebar__app-category_burger-icon"
                name="icons/burger"
                size="24px" />
            </div>

            <div
              v-else
              class="sidebar__app-category_selector"
              :class="{ 'sidebar__app-category_selector--active': isActive }">
              {{ SidebarCategoryEnum[appCategory].name }}

              <AppIcon
                name="icons/arrow2"
                :rotate="isActive ? '0deg' : '90deg'" />
            </div>
          </Transition>
        </div>
      </template>

      <template #container="{ close }">
        <div class="sidebar__app-category_selector-items_wrapper">
          <div class="sidebar__app-category_selector-items_shadow" />

          <div
            v-for="item in SidebarCategoryEnum"
            :class="{ 'sidebar__app-category_selector_item--active': item.value === appCategory }"
            class="sidebar__app-category_selector_item"
            @click="
              appCategory = item.value;
              close();
            ">
            {{ item.name }}
          </div>
        </div>
      </template>
    </AppDropdown>

    <NavigationBlock
      :app-category="appCategory"
      @logout="logout" />
  </div>
</template>

<script lang="ts" setup>
import { SidebarCategoryEnum } from '../../helpers';

import NavigationBlock from './components/NavigationBlock.vue';

const router = useRouter();

const sidebarStore = useSidebarStore();
const authStore = useAuthStore();

const appCategory = ref<(typeof SidebarCategoryEnum)[keyof typeof SidebarCategoryEnum]['value']>(SidebarCategoryEnum.Management.value);

function logout() {
  authStore.logout();
  router.push({ name: 'auth-login' });
}
</script>

<style lang="scss">
.sidebar__menu-dropdown {
  &.app-dropdown__content {
    background-color: #09342a;
    border-radius: 0px 12px 12px 0px;
    color: var(--c-nuetral--100);
  }
}
</style>

<style lang="scss" scoped>
:deep() {
  .sidebar__menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 0 10px 20px;
    height: 43px;
    cursor: pointer;
    transition:
      opacity var(--transition-value),
      color var(--transition-value),
      background-color var(--transition-value);

    &,
    &:link,
    &:visited {
      color: var(--c-nuetral--500);
      text-decoration: none;
    }

    &:not(.sidebar__menu-item--active):hover {
      color: var(--c-nuetral--100);
    }

    &:hover {
      .sidebar__menu-item_soon-badge {
        width: 50px;
        height: 20px;
        color: white;
      }
    }

    .sidebar__menu-item_border {
      position: absolute;
      left: 0;
      opacity: 0;
      background-color: var(--c-nuetral--100);
      width: 4px;
      height: calc(43px);
      z-index: 1;
      transform: scale(0.1) translateX(-16px);
      transition:
        opacity var(--transition-value),
        transform var(--transition-value);
    }

    .sidebar__menu-item_title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      transition: opacity var(--transition-value);
    }

    &--disabled {
      & .sidebar__menu-item_title,
      & .sidebar__menu-item_icon {
        opacity: 0.3;
      }
    }

    &--active {
      color: var(--c-nuetral--100) !important;

      .sidebar__menu-item_title {
        color: var(--c-nuetral--100);
      }

      .sidebar__menu-item_border {
        opacity: 1;
        transform: scale(1) translateX(0);
      }
    }
  }
}
.sidebar--compact {
  .main-sidebar {
    width: 64px;

    :deep() {
      .sidebar__menu-item_title,
      .sidebar__menu-item_soon-badge {
        opacity: 0 !important;
      }
    }
  }
}

.main-sidebar {
  display: flex;
  flex-direction: column;

  width: 224px;
  height: 100%;

  will-change: width, max-width;
  transition:
    background-color var(--transition-value),
    width 0.25s var(--transition-timing-function),
    max-width 0.25s var(--transition-timing-function);

  .sidebar__header_collapse-btn.app-button {
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    margin: 7px 0 7px 0;

    :deep(.app-button__left-icon) {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .sidebar__app-category {
    background-color: rgba(255, 255, 255, 0.1);
    transition: var(--transition-value);

    .sidebar__app-category_selector {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      height: 40px;
      color: var(--c-nuetral--500);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      transition:
        background-color var(--transition-value),
        color var(--transition-value);
      cursor: pointer;
    }

    .sidebar__app-category_burger {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      transition: background-color var(--transition-value);
    }

    &:hover,
    &--active {
      background-color: rgba(255, 255, 255, 0.2);

      .sidebar__app-category_selector {
        color: var(--c-nuetral--100);
      }

      .sidebar__app-category_burger-icon {
        background-color: var(--c-nuetral--100) !important;
      }
    }
  }

  .sidebar__app-category_burger-icon {
    transition: background-color var(--transition-value);
    background-color: var(--c-nuetral--500) !important;
  }
}
.sidebar__app-category_selector-items_wrapper {
  box-shadow: 0px 8px 50px 0px rgba(0, 82, 57, 0.4);
  .sidebar__app-category_selector-items_shadow {
    position: absolute;
    height: 100%;
    width: 30px;
  }

  .sidebar__app-category_selector_item {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: background-color var(--transition-value);
    cursor: pointer;

    &:hover:not(&--active) {
      background-color: rgba(255, 255, 255, 0.03);
    }

    &--active {
      background-color: rgba(255, 255, 255, 0.1);

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }
    }
  }
}
</style>
