import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { http } from '@/http';

export const useAuthStore = defineStore({
  id: 'auth',

  state: () => ({
    accessToken: localStorage.getItem('access-token'),
    refreshToken: localStorage.getItem('refresh-token'),
    Api1CToken: localStorage.getItem('1c-api-token'),
  }),

  actions: {
    removeTokens() {
      this.accessToken = null;
      this.refreshToken = null;
      this.Api1CToken = null;
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('1c-api-token');
    },

    setTokens({ accessToken = '', refreshToken = '', Api1CToken = '' }) {
      if (accessToken) {
        this.accessToken = accessToken;
        localStorage.setItem('access-token', accessToken);
      }

      if (refreshToken) {
        this.refreshToken = refreshToken;
        localStorage.setItem('refresh-token', refreshToken);
      }

      if (Api1CToken) {
        this.Api1CToken = Api1CToken;
        localStorage.setItem('1c-api-token', Api1CToken);
      }
    },

    logout() {
      this.removeTokens();
    },

    async checkAcccountForFirstAuthRequest({ data = {} }) {
      const url = '/authentication/v2/checkAccount';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async checkTemporaryCodeRequest({ data = {} }) {
      const url = '/authentication/v2/checkTemporaryCode';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async confirmTemporaryCodeRequest({ data = {} }) {
      const url = '/authentication/v2/confirmTemporaryCode';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async restoreLoginRequest({ data = {} }) {
      const url = '/authentication/v2/restoreLogin';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async loginFirstTimeRequest({ data = {} }) {
      const url = '/authentication/v2/loginFirstTime';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async confirmAgreementsRequest({ data = {} }) {
      const url = '/authentication/v2/confirmAgreements';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async setPasswordRequest({ data = {} }) {
      const url = '/authentication/v2/setPassword';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async restorePasswordRequest({ data = {} }) {
      const url = '/authentication/v2/restorePassword';

      const { data: reqData } = await http.post(url, data);

      return { reqData };
    },

    async loginRequest({ data = {} }) {
      const { data: reqData } = await http.legacyIdentity.authenticationV2LoginCreate(data);

      console.log('🍉 LOGIN REQUEST, RESPONSE:', reqData);

      if (reqData) {
        this.setTokens({ accessToken: reqData.accessToken, refreshToken: reqData.refreshToken });

        // const integration1CToken = await http.post('/integrationInstances/payments/1c/token');

        // this.setTokens({ Api1CToken: integration1CToken.data.data.token });

        console.log('🍉 LOGIN REQUEST, LOCAL STORE:', {
          'access-token': this.accessToken,
          'refresh-token': this.refreshToken,
        });
      }

      return reqData;
    },

    getDeviceId() {
      let deviceId = localStorage.getItem('device-id');

      if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('device-id', deviceId);
      }

      return deviceId;
    },
  },
});
