<template>
  <div
    class="flex flex-col"
    style="height: 100%">
    <div class="text-header-1 mb-6">Фильтры</div>

    <div
      class="flex flex-col flex-2 gap-4 scrollbar-default"
      style="overflow-y: auto">
      <slot />
    </div>

    <div class="text-grey text-secondary text-center mt-4 mb-4">
      найдено <span class="text-black">{{ itemsCount }}</span> {{ numWord(itemsCount, ['лицо', 'лица', 'лиц']) }}
    </div>

    <div class="flex flex-col gap-3">
      <AppButton
        type="primary"
        @click="$emit('accept')">
        Сохранить
      </AppButton>

      <AppButton
        type="outline"
        @click="$emit('reset')">
        Сбросить
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { numWord } from '@/helpers';
import debounce from '@/helpers/debounce';
import { http } from '@/http';

interface Props {
  localFilter: any;
  countHandler: any;
}

interface Emits {
  (e: 'accept'): void;
  (e: 'reset'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const itemsCount = ref(0);

async function getItemsCountByFilters() {
  const test = Object.fromEntries(
    Object.entries(props.localFilter).map((item) => {
      return [item[0], item[1] ? item[1] : undefined];
    })
  );
  const { data } = await http.person.countList(test);
  itemsCount.value = data;
}

const getCountDebounced = debounce(getItemsCountByFilters, 500);
watch(props.localFilter, getCountDebounced, { deep: true });

onBeforeMount(() => {
  getItemsCountByFilters();
});
</script>
