<template>
  <div class="flex flex-col mt-4 flex-1">
    <div
      v-if="menuItems.length"
      class="sidebar__menu">
      <Component
        v-for="item in menuItems || []"
        :key="item.routeName"
        :to="{ name: item.routeName }"
        class="sidebar__menu-item"
        :class="{
          'sidebar__menu-item--active': $route.name === item.routeName || ($route.matched[1].name as string).includes(item.routeName),
          'sidebar__menu-item--disabled': item.disabled || item.soon,
        }"
        :is="item.disabled || item.soon ? 'div' : 'router-link'">
        <div class="sidebar__menu-item_border" />

        <AppIcon
          class="sidebar__menu-item_icon"
          size="20px"
          :name="`icons/sidebar/${item.icon}`" />

        <div class="sidebar__menu-item_title">
          {{ item.title }}
        </div>

        <div
          v-if="item.soon && !sidebarStore.isCompact"
          class="sidebar__menu-item_soon-badge">
          Скоро
        </div>
      </Component>
    </div>

    <div class="sidebar__footer">
      <div
        class="sidebar__menu-item"
        @click="$emit('logout')">
        <div class="sidebar__menu-item_border" />

        <AppIcon
          class="sidebar__menu-item_icon"
          size="20px"
          name="icons/sidebar/logout" />

        <div class="sidebar__menu-item_title">Выйти</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SidebarCategoryEnum, SidebarCategoryMenuItemsEnum } from '../../../helpers';

type Props = {
  appCategory: (typeof SidebarCategoryEnum)[keyof typeof SidebarCategoryEnum]['value'];
};

type Emits = {
  logout: [];
};

const props = withDefaults(defineProps<Props>(), {
  appCategory: SidebarCategoryEnum.Management.value,
});

defineEmits<Emits>();

const sidebarStore = useSidebarStore();

const menuItems = computed(() => {
  return SidebarCategoryMenuItemsEnum[props.appCategory].menuItems;
});
</script>

<style lang="scss" scoped>
.sidebar__footer {
  padding: 16px 0 30px;
  margin-top: auto;
}
</style>
