<template>
  <AppDropdown
    ref="appDropdownRef"
    placement="bottom-end"
    :offset="[-20, -20]">
    <template #default="{ isActive }">
      <AppButton
        type="text"
        icon-left="icons/more"
        class="app-menu-button"
        :class="{ 'app-menu-button--active': isActive }"
        @click.stop />
    </template>

    <template #container>
      <slot v-if="appDropdownRef" />
    </template>
  </AppDropdown>
</template>

<script lang="ts" setup>
import { AppDropdownInstanceType, AppDropdownRefInjectionKey } from './helpers';

const appDropdownRef = ref<AppDropdownInstanceType>(null);

provide(AppDropdownRefInjectionKey, appDropdownRef);
</script>

<style lang="scss" scoped>
.app-menu-button.app-menu-button--active {
  background-color: var(--c-primary--50);
}
</style>
