<template>
  <div
    v-if="!globalStore.isMainContentLoading"
    class="app-form-page-wrapper"
    v-loading="isLoading">
    <div
      class="flex justify-between mb-6"
      style="padding: 0 24px">
      <div class="text-header-1 header-underline">
        {{ pageTitle }}
      </div>

      <AppButton
        type="primary"
        @click="$emit('confirm')">
        Сохранить
      </AppButton>
    </div>

    <ElForm
      class="flex-2 flex flex-col gap-6"
      style="max-width: 744px"
      label-position="top"
      @submit.stop.prevent>
      <slot />
    </ElForm>

    <AppFormStepper
      :steps="steps"
      @next="$router.push({ name: $event })"
      @prev="$router.push({ name: $event })" />
  </div>
</template>

<script setup lang="ts">
type Props = {
  isLoading: boolean;
  pageTitle: string;
};

type Emits = {
  (e: 'confirm'): void;
};

defineProps<Props>();
defineEmits<Emits>();

const globalStore = useGlobalStore();
const sidebarStore = useSidebarStore();

const steps = computed(() => {
  return sidebarStore.detailRoutesData.map((item) => {
    return item.routeName;
  });
});
</script>

<style lang="scss" scoped>
.app-form-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
</style>
