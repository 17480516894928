<template>
  <div class="detail-sidebar">
    <div class="sidebar__header mb-6">
      <Transition name="fade">
        <div
          class="flex items-center gap-2 cursor-pointer"
          @click="$router.push({ name: 'start' })">
          <img
            src="/logo/logo-img.png"
            width="38"
            height="38" />

          <div class="sidebar__header_logo-text" />
        </div>
      </Transition>
    </div>

    <div class="sidebar__header-actions">
      <AppButton
        class="sidebar__header_go-back"
        type="link"
        icon-left="icons/arrow"
        @click="$router.push(sidebarStore.backlinkRoutesStack.at(-1))">
        <span
          class="text-primary"
          style="margin-left: 4px; font-weight: 500">
          {{ sidebarStore.backlinkTitlesStack.at(-1) || 'Вернуться' }}
        </span>
      </AppButton>
    </div>

    <div class="detail-sidebar__separator" />

    <NavigationBlock />
  </div>
</template>

<script lang="ts" setup>
import NavigationBlock from './components/NavigationBlock.vue';

const sidebarStore = useSidebarStore();
</script>

<style lang="scss" scoped>
.sidebar__header-actions {
  padding: 12px 24px;
  margin-bottom: 12px;
}

.detail-sidebar {
  display: flex;
  flex-direction: column;

  width: 224px;
  height: 100%;

  .sidebar__header_go-back.app-button {
    color: var(--c-nuetral--100);

    &:hover {
      color: var(--c-nuetral--100);
      opacity: 0.9;
    }

    &:active {
      color: var(--c-nuetral--100);
      opacity: 0.7;
    }
  }
}

.detail-sidebar__separator {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.05);
}
</style>
