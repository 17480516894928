import { defineStore } from 'pinia';
import { LocationQuery } from 'vue-router';

interface DetailRouteModel {
  title: string;
  routeName: string;
  soon?: boolean;
  hasErrors?: boolean;
  routeQuery?: LocationQuery;
}

interface DetailPageConfig {
  backlink: { title: string; route: Record<string, any> };
  routes: DetailRouteModel[];
}

export const useSidebarStore = defineStore('sidebar', () => {
  const route = useRoute();

  const isCompact = ref(false);

  const backlinkRoutesStack = ref<Record<string, any>[]>([]);
  const backlinkTitlesStack = ref<string[]>([]);

  const detailRoutesData: Ref<DetailRouteModel[]> = ref([]);

  function setDetailPageConfig(config: DetailPageConfig) {
    detailRoutesData.value = config.routes;
    if (!backlinkRoutesStack.value.length) {
      backlinkTitlesStack.value = [config.backlink.title];
      backlinkRoutesStack.value = [config.backlink.route];
    }
  }

  const currentDetailTab = computed(() => {
    return detailRoutesData.value.find((el) => {
      return el.routeName === route.name;
    });
  });

  return { detailRoutesData, currentDetailTab, backlinkRoutesStack, setDetailPageConfig, backlinkTitlesStack, isCompact };
});
