<template>
  <div
    v-bind="{ ...$attrs }"
    class="app-checkbox flex gap-2 items-center"
    :class="{ '--disabled': disabled || globalDisabled, '--active': modelValue || indeterminate || groupModelValue?.includes(value) }"
    @click.prevent="disabled || globalDisabled ? null : onChange()">
    <svg
      height="16"
      width="16"
      :style="{ order: direction === 'right' ? 1 : 2 }">
      <rect
        class="background"
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5" />

      <path
        v-if="indeterminate"
        class="minus"
        d="M3.63636 8.25462C3.63636 7.85295 3.96197 7.52734 4.36364 7.52734H11.6364C12.038 7.52734 12.3636 7.85295 12.3636 8.25462C12.3636 8.65628 12.038 8.98189 11.6364 8.98189H4.36364C3.96197 8.98189 3.63636 8.65628 3.63636 8.25462Z" />

      <path
        v-else
        class="check"
        d="M6.18086 12.2321C6.11892 12.1948 6.05933 12.14 6.00513 12.0674L3.83071 9.15489C3.59042 8.83304 3.54714 8.28388 3.73403 7.92834C3.92092 7.57281 4.26724 7.54548 4.50753 7.86733L6.51814 10.5604L11.4559 3.9466C11.6962 3.62473 12.0425 3.65203 12.2294 4.00758C12.4163 4.36313 12.3731 4.91228 12.1327 5.23415L6.91378 12.2246C6.68995 12.5244 6.37414 12.5213 6.18086 12.2321Z" />
    </svg>

    <span :style="{ order: direction === 'right' ? 2 : 1 }">
      <slot />
    </span>
  </div>

  <input
    :id="id"
    type="checkbox"
    :checked="modelValue || indeterminate || groupModelValue?.includes(value)"
    :disabled="disabled || globalDisabled"
    hidden
    @click.stop.prevent="disabled || globalDisabled ? null : onChange()" />
</template>

<script lang="ts" setup>
import { CheckboxValue } from './types';
import { CheckboxInjectionKey } from './constants';

interface Props {
  modelValue?: boolean;
  value?: CheckboxValue;
  disabled?: boolean;
  indeterminate?: boolean;
  id?: string;
  direction?: 'left' | 'right';
}

interface Emits {
  (e: 'update:model-value', value: boolean);
  (e: 'change', value: boolean);
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  indeterminate: false,
  id: 'default-checkbox-id',
  direction: 'right',
});

const emit = defineEmits<Emits>();

function onChange() {
  if (isCheckboxGroup) {
    emit('change', !groupModelValue.value?.includes(props.value));
    emitUpdateModelValue(props.value);
  } else {
    emit('change', !props.modelValue);
    emit('update:model-value', !props.modelValue);
  }
}

const { isCheckboxGroup, globalDisabled, groupModelValue, emitUpdateModelValue } = inject(CheckboxInjectionKey, {
  isCheckboxGroup: false,
  globalDisabled: false,
  groupModelValue: undefined,
  emitUpdateModelValue: undefined,
});
</script>

<style lang="scss" scoped>
.app-checkbox {
  cursor: pointer;
  width: fit-content;
  flex-shrink: 0;

  &:not(.--disabled):hover {
    .background {
      stroke: var(--c-primary--400);
      fill: var(--c-primary--50);
    }

    &.--active {
      .background {
        fill: var(--c-primary--400);
      }
    }
  }

  &.--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.--active {
    .background {
      fill: var(--c-primary--500);
    }

    .minus {
      opacity: 1;
    }

    .check {
      opacity: 1;
    }
  }
}

.background {
  transition: stroke 0.13s, fill 0.13s;
  stroke: var(--c-primary--500);
  fill: var(--c-nuetral--100);
}

.minus {
  transition: opacity 0.13s;
  fill: var(--c-nuetral--100);
  opacity: 0;
}

.check {
  transition: opacity 0.13s;
  fill: var(--c-nuetral--100);
  opacity: 0;
}
</style>
