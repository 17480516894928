<template>
  <div
    v-if="!drawerStore.isLoading"
    class="app-global-drawer-wrapper">
    <AppTabs
      v-model="tabValue"
      :title="tabsOptions.title"
      style="grid-column: 1/2; grid-row: 1/2">
      <AppTabsItem
        v-for="tab in tabsOptions.tabs"
        :name="tab.name"
        :title="tab.title">
        <div
          class="flex flex-col"
          style="min-height: 100%; grid-column: 1/2; grid-row: 2/3">
          <component
            :is="tab.component"
            v-bind="tab.props" />
        </div>
      </AppTabsItem>
    </AppTabs>

    <div class="flex justify-end">
      <slot name="menu" />
    </div>

    <div class="app-global-drawer-wrapper__widgets scrollbar-default">
      <slot name="widgets" />
    </div>
  </div>

  <DrawerLoading v-else />
</template>

<script lang="ts" setup>
import { VNode } from 'vue';

import DrawerLoading from '../components/TheGlobalDrawer/components/DrawerLoading.vue';

type TabOption = { name: string; title: string; component: VNode; props?: Record<string, any> };

interface Props {
  tabsOptions: { title: string; tabs: TabOption[] };
}

const props = defineProps<Props>();

const drawerStore = useDrawerStore();

const tabValue = ref(props.tabsOptions.tabs[0].name);
</script>

<style lang="scss" scoped>
.app-global-drawer-wrapper {
  display: grid;
  overflow-y: hidden;
  height: 100%;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content 1fr;
  row-gap: 24px;
  column-gap: 16px;
}

.app-global-drawer-wrapper__widgets {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
