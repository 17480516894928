<template>
  <teleport to="#app-drawer-wrapper">
    <transition
      name="fade"
      mode="out-in"
      appear
      @enter="drawerContentIsVisible = true">
      <div
        v-if="modelValue"
        class="app-drawer-overlay"
        :style="{ '--app-drawer-z-index': currentIndex }"
        @click.prevent.stop="closeOnClickOverlay ? (drawerContentIsVisible = false) : null">
        <transition
          name="slide"
          mode="out-in"
          appear
          @leave="emit('update:model-value', false)">
          <div
            v-if="drawerContentIsVisible"
            class="app-drawer flex"
            :style="{ width: drawerWidthComputed }"
            @click.stop>
            <div class="app-drawer__actions flex flex-col gap-4">
              <div
                class="action-button"
                @click="drawerContentIsVisible = false">
                <app-icon
                  name="icons/cross"
                  size="20px"
                  color="var(--c-nuetral--800)" />
              </div>

              <div
                v-if="drawerStore.drawersStack.length > 1 && !drawerStore.isLoading"
                class="action-button cursor-pointer"
                style="background-color: var(--c-primary--500)"
                @click="$emit('back')">
                <app-icon
                  name="icons/arrow"
                  size="20px"
                  color="var(--c-nuetral--100)" />
              </div>
            </div>
            <div class="app-drawer__body scrollbar-default"><slot /></div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { useZIndex } from '@/composition/useZIndex';
import { useNestIndex } from '@/composition/useNestIndex';
import { onKeyStroke } from '@vueuse/core';

const props = defineProps({
  destroyOnClose: { type: Boolean, default: true },
  width: { type: String, default: '1170px' },
  modelValue: Boolean,
  closeOnPressEsc: { type: Boolean, default: true },
  closeOnClickOverlay: { type: Boolean, default: true },
});

const emit = defineEmits(['update:model-value', 'close', 'closed', 'back']);

const drawerContentIsVisible = ref(false);

const drawerWidthComputed = computed(() => {
  return props.width;
});

const drawerStore = useDrawerStore();

let currentIndex;
let escCloseFn;

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      currentIndex = useZIndex.value;
      useNestIndex.add(currentIndex);
      if (props.closeOnPressEsc) {
        escCloseFn = onKeyStroke('Escape', () => {
          if (currentIndex === useNestIndex.lastValue()) {
            drawerContentIsVisible.value = false;
            emit('close');
          }
        });
      }
    } else {
      useNestIndex.remove();
      drawerContentIsVisible.value = false;
      escCloseFn?.();
      emit('close');
    }
  }
);
</script>

<style lang="scss" scoped>
.app-drawer__body {
  overflow-y: auto;
  flex: 2;
}

.app-drawer {
  --bg-color: var(--c-nuetral--300);

  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: var(--bg-color);
  border-radius: 32px 0 0 32px;
  z-index: 1001;

  .app-drawer__actions {
    position: absolute;
    left: -40px;
    top: 96px;
  }

  .app-drawer__body {
    padding: 32px 24px;
  }

  .action-button {
    padding: 10px;
    background-color: var(--c-nuetral--300);
    border-radius: 12px 0px 0px 12px;
    cursor: pointer;
    will-change: transform;
    transition: transform 0.23s ease-in-out;

    &:active {
      .app-icon {
        transform: scale(0.8);
      }
    }
  }
}

.app-drawer-overlay {
  --app-drawer-z-index: 1000;

  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: var(--app-drawer-z-index);
}

.slide-enter-active {
  transition: all 0.23s ease-in-out;
}

.slide-leave-active {
  transition: all 0.23s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(50%);
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.23s ease-in-out;
}

.fade-leave-active {
  transition: opacity 0.23s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
