<template>
  <button
    ref="appButtonRef"
    class="app-button"
    :class="[`--${type}`, { '--icon-left': iconLeft, '--icon-right': iconRight, '--icon-only': !slots.default }]"
    @click="$emit('click', $event)">
    <AppIcon
      v-if="iconLeft"
      class="app-button__left-icon"
      :name="iconLeft"
      :size="type === 'link' ? '16px' : '20px'"
      :rotate="iconLeftRotate" />

    <slot />

    <AppIcon
      v-if="iconRight"
      class="app-button__right-icon"
      :name="iconRight"
      :size="type === 'link' ? '16px' : '20px'"
      :rotate="iconRightRotate" />
  </button>
</template>

<script lang="ts" setup>
interface Props {
  type: 'primary' | 'outline' | 'text' | 'alert' | 'link';
  iconLeft?: GlobalIconsNames;
  iconRight?: GlobalIconsNames;
  iconLeftRotate?: string;
  iconRightRotate?: string;
}

interface Emits {
  (e: 'click', event: MouseEvent);
}

withDefaults(defineProps<Props>(), {
  iconLeft: null,
  iconRight: null,
  iconLeftRotate: '0deg',
  iconRightRotate: '0deg',
});

defineEmits<Emits>();

const slots = useSlots();

const appButtonRef = ref(null);

defineExpose({ appButtonRef });
</script>

<style lang="scss" scoped>
.app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 32px;
  border-radius: 12px;
  font-family: inherit;

  flex-shrink: 0;
  outline: none;
  border: none;
  transition:
    background-color 0.13s ease-in,
    color 0.13s ease-in;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &.--icon-right {
    padding-right: 24px;
  }

  &.--icon-left {
    padding-left: 24px;
  }

  &.--icon-only {
    padding: 10px;
  }

  &.--link {
    height: max-content;
    width: max-content;
    background-color: transparent;
    padding: 0;
    color: var(--c-primary--700);
    gap: 2px;

    &:hover:not(:disabled) {
      color: var(--c-primary--400);
    }

    &:active:not(:disabled) {
      color: var(--c-nuetral--800);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--primary {
    background: var(--c-primary--600);
    color: var(--c-nuetral--100);

    &:hover:not(:disabled) {
      background: var(--c-primary--400);
    }

    &:active:not(:disabled) {
      background: var(--c-primary--700);
    }

    &:disabled {
      background-color: var(--c-primary--500);
      opacity: 0.3;
    }
  }

  &.--outline {
    background: var(--c-nuetral--300);
    color: var(--c-nuetral--800);

    &:hover:not(:disabled) {
      background: var(--c-primary--50);
    }

    &:active:not(:disabled) {
      background: var(--c-primary--100);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--text {
    background: transparent;
    color: var(--c-nuetral--800);

    &:hover:not(:disabled) {
      background: var(--c-primary--50);
    }

    &:active:not(:disabled) {
      background: var(--c-primary--100);
    }

    &:disabled {
      color: rgba(40, 57, 54, 0.3);
    }
  }

  &.--alert {
    background: var(--c-nuetral--300);
    color: var(--c-alert--500);

    &:hover:not(:disabled) {
      background: var(--c-alert--100);
    }

    &:active:not(:disabled) {
      background: var(--c-alert--300);
      color: var(--c-alert--700);
    }
  }
}
</style>
