import { createApp } from 'vue';
import { createPinia } from 'pinia';

import 'element-plus/dist/index.css';
import './styles/index.scss';

import AppIcon from './ui/AppIcon.vue';
import AppEmpty from './ui/AppEmpty.vue';
import AppSelectScroll from './ui/AppSelectScroll.vue';
import AppDrawer from './ui/AppDrawer.vue';
import AppWidget from './ui/AppWidget/AppWidget.vue';
import AppWidgetItem from './ui/AppWidget/AppWidgetItem.vue';
import AppTable from './ui/AppTable/AppTable.vue';
import AppTableColumn from './ui/AppTable/AppTableColumn.vue';
import AppButton from './ui/AppButton.vue';
import AppCloseButton from './ui/AppCloseButton.vue';
import AppTabs from './ui/AppTabs/AppTabs.vue';
import AppTabsItem from './ui/AppTabs/AppTabsItem.vue';
import { AppCheckbox, AppCheckboxGroup } from './ui/AppCheckbox';
import { AppPagination, AppPaginationFooter } from './ui/AppPagination';
import { AppRadio, AppRadioItem, AppRadioSelect, AppRadioSelectItem } from './ui/AppRadio';
import { AppMenuButton, AppMenuButtonItem } from './ui/AppMenuButton';
import AppTag from './ui/AppTag.vue';
import AppDivider from './ui/AppDivider.vue';
import AppDialog from './ui/AppDialog';
import AppDialogFooter from './ui/AppDialog/AppDialogFooter';
import AppSwitch from './ui/AppSwitch.vue';
import AppFormStepper from './ui/AppFormStepper.vue';
import { AppLabel, AppLabelGroup } from './ui/AppLabel';
import AppAlert from './ui/AppAlert.vue';
import AppTooltip from './ui/AppTooltip.vue';
import AppDropdown from './ui/AppDropdown.vue';
import AppFilterPreview from './ui/AppFilterPreview.vue';
import AppFormPageWrapper from './ui/AppFormPageWrapper.vue';
import AppAvatar from './ui/AppAvatar.vue';
import AppMoreDropdown from './ui/AppMoreDropdown.vue';
import AppGlobalDrawerWrapper from './ui/AppGlobalDrawerWrapper.vue';
import AppTablePageWrapper from './ui/AppTablePageWrapper.vue';
import AppFilterDrawerWrapper from './ui/AppFilterDrawerWrapper.vue';

import App from './App.vue';
import router from './router';

import VueTheMask, { tokens } from 'vue-the-mask';
import VueProgressBar from '@aacassandra/vue3-progressbar';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const app = createApp(App);

tokens['D'] = {
  pattern: /[АВЕКМНОРСТУХавекмнорстух]/,
};
app.use(createPinia());
app.use(router);

app.use(VueTheMask);
app.use(VueProgressBar, {
  color: 'var(--c-primary--400)',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
});

app.component('AppIcon', AppIcon);
app.component('AppEmpty', AppEmpty);
app.component('AppSelectScroll', AppSelectScroll);
app.component('AppDrawer', AppDrawer);
app.component('AppWidget', AppWidget);
app.component('AppWidgetItem', AppWidgetItem);
app.component('AppTable', AppTable);
app.component('AppTableColumn', AppTableColumn);
app.component('AppButton', AppButton);
app.component('AppCloseButton', AppCloseButton);
app.component('AppTabs', AppTabs);
app.component('AppTabsItem', AppTabsItem);
app.component('AppRadioSelect', AppRadioSelect);
app.component('AppRadioSelectItem', AppRadioSelectItem);
app.component('AppMenuButton', AppMenuButton);
app.component('AppMenuButtonItem', AppMenuButtonItem);
app.component('AppTag', AppTag);
app.component('AppDivider', AppDivider);
app.component('AppDialog', AppDialog);
app.component('AppDialogFooter', AppDialogFooter);
app.component('AppSwitch', AppSwitch);
app.component('AppRadio', AppRadio);
app.component('AppRadioItem', AppRadioItem);
app.component('AppCheckbox', AppCheckbox);
app.component('AppCheckboxGroup', AppCheckboxGroup);
app.component('AppPagination', AppPagination);
app.component('AppPaginationFooter', AppPaginationFooter);
app.component('AppFormStepper', AppFormStepper);
app.component('AppLabel', AppLabel);
app.component('AppLabelGroup', AppLabelGroup);
app.component('AppAlert', AppAlert);
app.component('AppTooltip', AppTooltip);
app.component('AppDropdown', AppDropdown);
app.component('AppFilterPreview', AppFilterPreview);
app.component('AppFormPageWrapper', AppFormPageWrapper);
app.component('AppAvatar', AppAvatar);
app.component('AppMoreDropdown', AppMoreDropdown);
app.component('AppGlobalDrawerWrapper', AppGlobalDrawerWrapper);
app.component('AppFilterDrawerWrapper', AppFilterDrawerWrapper);
app.component('AppTablePageWrapper', AppTablePageWrapper);

app.mount('#app');
