<template>
  <div
    class="app-radio-select-item"
    :class="{
      '--active': value === modelValue,
      '--icon': icon,
      '--disabled': disabled,
    }"
    :disabled="disabled"
    @click="disabled ? null : (modelValue = value)">
    <slot></slot>

    <app-icon
      v-if="icon"
      :name="icon"
      size="16px" />
  </div>
</template>

<script lang="ts" setup>
import { RadioSelectInjectionKey } from './constants';
import { RadioModelValue } from './types';

interface Props {
  value: RadioModelValue;
  icon?: GlobalIconsNames;
  disabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  disabled: false,
});

const modelValue = inject(RadioSelectInjectionKey);
</script>

<style lang="scss" scoped>
.app-radio-select-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 100%;
  padding: 0 16px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  border-radius: 24px;
  background-color: var(--c-nuetral--300);
  transition:
    background-color 0.13s ease-in,
    color 0.13s ease-in;
  cursor: pointer;

  &:disabled,
  &.--disabled {
    background-color: var(--c-nuetral--300);
    color: var(--c-nuetral--500);
  }

  &:hover:not(&.--active, &.--disabled) {
    background-color: var(--c-nuetral--600);
    color: var(--c-nuetral--100);
  }

  &.--icon {
    padding: 0 12px 0 16px;
  }

  &.--active {
    background-color: var(--c-nuetral--800);
    color: var(--c-nuetral--100);
  }
}
</style>
