import { AppRouteRecord } from '.';
import { loadImport } from './helpers';

export const startRoutes = [
  {
    name: 'start',
    path: '',
    redirect: { name: 'villages' },
    meta: {
      requiresAuth: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
