<template>
  <div
    class="app-table"
    ref="tableWrapper">
    <div
      class="app-table__content scrollbar-default"
      :class="{
        'app-table__fixed-left-column': fixedLeftColumn,
        'app-table__fixed-right-column': fixedRightColumn,
      }">
      <div class="app-table__header">
        <div
          v-for="(item, index) in tableColumns"
          ref="headerItems"
          class="app-table__header-item"
          :class="{
            'app-table__header-item--sort': item['sort-by'],
          }"
          :style="{ '--cell-padding': item.padding }"
          @click="item['sort-by'] ? emit('sort', item['sort-by'].key) : null">
          <app-checkbox
            v-if="item.selectable"
            :model-value="item['selected-value']"
            :indeterminate="item['is-indeterminate']"
            :label="item.title"
            @update:model-value="emit('change-checkbox', { value: $event, index, item })"></app-checkbox>

          <span v-else>
            {{ item.title }}
          </span>

          <div
            v-if="item['sort-by']"
            class="flex flex-col">
            <app-icon
              name="icons/arrow2"
              :color="item['sort-by'].value ? 'var(--c-nuetral--800)' : 'var(--c-nuetral--600)'"
              size="16px"
              rotate="-90deg"></app-icon>
            <app-icon
              name="icons/arrow2"
              :color="!item['sort-by'].value ? 'var(--c-nuetral--800)' : 'var(--c-nuetral--600)'"
              size="16px"
              rotate="90deg"></app-icon>
          </div>
        </div>
      </div>

      <div
        v-if="dateDivider"
        class="app-table__body">
        <template
          v-for="(data, datetime) in tableDataComputed"
          :key="datetime">
          <div data-type="divider">{{ datetime }}</div>

          <div
            v-for="(item, index) in data"
            :key="item.id"
            class="app-table__row"
            :class="{
              'app-table__row--expanded': expandRows[`${datetime}/${index}`],
            }"
            :style="{ cursor: $slots.expand ? 'pointer' : 'initial' }"
            ref="rowItems"
            @click.stop.prevent="onRowClick({ e: $event, item, index, datetime })">
            <div
              v-if="$slots.expand"
              class="app-table__row-expand-arrow">
              <app-icon
                name="icons/arrow2"
                :rotate="expandRows[`${datetime}/${index}`] ? '270deg' : '90deg'"
                color="var(--c-primary--600)"></app-icon>
            </div>

            <slot
              name="item"
              :item="item"
              :hovered="item._index === hoveredIndex">
            </slot>

            <div
              v-if="$slots.expand"
              class="app-table__row-expand"
              ref="rowItemsExpand"
              @click.stop.prevent>
              <div
                v-if="expandRows[`${datetime}/${index}`]"
                style="height: 100%">
                <slot
                  name="expand"
                  :item="item"></slot>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-else
        class="app-table__body">
        <div
          v-for="(item, index) in tableDataComputed"
          class="app-table__row"
          :class="{
            'app-table__row--expanded': expandRows[index],
          }"
          :style="{ cursor: $slots.expand ? 'pointer' : 'initial' }"
          ref="rowItems"
          @click="onRowClick({ e: $event, item, index })">
          <div
            v-if="$slots.expand"
            class="app-table__row-expand-arrow">
            <app-icon
              name="icons/arrow2"
              :rotate="expandRows[index] ? '270deg' : '90deg'"
              color="var(--c-primary--600)"></app-icon>
          </div>

          <slot
            name="item"
            :item="item"
            :hovered="item._hovered"></slot>

          <div
            v-if="$slots.expand"
            class="app-table__row-expand"
            ref="rowItemsExpand"
            @click.stop.prevent>
            <div
              v-if="expandRows[index]"
              style="height: 100%">
              <slot
                name="expand"
                :item="item"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends any[]">
import { ref, reactive, computed, watch, useSlots } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

interface Props {
  tableData: T;
  dateDivider?: boolean;
  infiniteScroll?: boolean;
  accordion?: boolean;
  fixedLeftColumn?: boolean;
  fixedRightColumn?: boolean;
}

interface Slots {
  item(props: { item: T[number]; hovered: boolean }): any;
}

const props = withDefaults(defineProps<Props>(), {
  accordion: false,
  dateDivider: false,
  infiniteScroll: false,
});

defineSlots<Slots>();

const emit = defineEmits(['infinite-scroll', 'row-click', 'sort', 'row-mouseenter', 'row-mouseleave', 'change-checkbox']);

const tableDataComputed = computed(() => {
  if (props.dateDivider) {
    Object.values(props.tableData)
      .flat()
      .forEach((item, index) => {
        item._index = index;
      });
  } else {
    props.tableData.forEach((item, index) => {
      item._index = index;
    });
  }

  return props.tableData;
});

const slots = useSlots();

const hoveredIndex = ref(null);

const tableColumns = computed(() => {
  const itemSlot = slots.item({});
  let result;
  if (itemSlot.length === 1 && itemSlot[0].key === '_default') {
    console.log(itemSlot);
    result = itemSlot[0].children.filter((item) => item.children.default);
  } else {
    result = itemSlot.filter((item) => item.children.default);
  }

  if (slots.expand) {
    result.unshift({ props: { width: 'max-content', isExpand: true } });
  }

  return result.map((item) => {
    const itemProps = item.props;

    return {
      title: '',
      width: null,
      min: '120px',
      max: '1fr',
      padding: '0 24px',

      ...(itemProps ?? {}),
    };
  });
});

const tableWrapper = ref(null);
const rowItems = ref();
const rowItemsExpand = ref();

const gridColTemplate = computed(() => {
  const items = tableColumns.value;

  return items
    ?.map((item) => {
      // return (item.width ? item.width : `minmax(${item.min}, ${item.max})`);

      if (item.width) return item.width;
      else if (!item.max.includes('fr')) return `fit-content(${item.max})`;
      else if (item.min) return `minmax(${item.min}, 1fr)`;
      else return `minmax(auto, 1fr)`;
    })
    .join(' ');
});

const headerItems = ref([]);

const rowHoveredIndex = ref(null);

const expandRows = reactive({});

function onRowClick({ e, index, datetime, item }) {
  if (slots.expand) {
    const rowKey = datetime ? `${datetime}/${index}` : index;

    if (props.accordion) {
      Object.entries(expandRows)
        .filter(([key]) => String(key) !== String(rowKey))
        .forEach(([key, { el, elExpand }]) => {
          delete expandRows[key];
        });
    }

    if (expandRows[rowKey]) {
      delete expandRows[rowKey];
    } else {
      expandRows[rowKey] = true;
    }
  }

  emit('row-click', { index, datetime, item });
}

if (props.infiniteScroll) {
  watch(
    rowItems,
    (value) => {
      if (!value) return;
      const lastItem = rowItems.value[rowItems.value?.length - 1];

      if (lastItem) {
        const { stop } = useIntersectionObserver(
          lastItem.children[0],
          ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
              emit('infinite-scroll');
              stop();
            }
          },
          { root: tableWrapper.value.wrap$ }
        );
      }
    },

    {
      flush: 'post',
      immediate: true,
      deep: true,
    }
  );
}
</script>

<style lang="scss">
.app-table {
  --cell-padding: 0 24px;

  height: 100%;
  overflow: hidden;

  .app-table__fixed-left-column {
    .app-table__header-item:first-child {
      position: sticky;
      left: 0;
      z-index: 1002;
    }

    .app-table__row .app-table__cell:first-child {
      position: sticky;
      left: 0;
      z-index: 1001;
      box-shadow: 4px 0 1px -2px var(--c-nuetral--300);
    }

    .app-table__row .app-table__cell {
      background-color: var(--c-nuetral--100);
      z-index: 999;
    }
  }

  .app-table__fixed-right-column {
    .app-table__header-item:last-child {
      position: sticky;
      right: 0;
      z-index: 1002;
    }

    .app-table__row .app-table__cell:last-child {
      position: sticky;
      right: 0;
      z-index: 1001;
      box-shadow: 4px 0 1px -2px var(--c-nuetral--300);
      box-shadow: -4px 1px 3px -2px var(--c-nuetral--300);
    }

    .app-table__row .app-table__cell {
      background-color: var(--c-nuetral--100);
      z-index: 999;
    }
  }

  .app-table__content {
    display: grid;
    grid-template-columns: v-bind(gridColTemplate);
    grid-auto-rows: max-content;
    height: 100%;
    overflow: auto;
  }

  .app-table__header,
  .app-table__body,
  .app-table__row {
    display: contents;
  }

  .app-table__body {
    height: 100%;
  }

  .app-table__header-item {
    display: flex;
    gap: 8px;
    position: sticky;
    top: 0;

    color: var(--c-nuetral--600);
    background-color: var(--c-nuetral--200);
    border-bottom: 1px solid var(--c-nuetral--300);
    border-top: 1px solid var(--c-nuetral--300);
    transform: translate3d(0, 0, 0);
    transform-style: preserver-3d;
    backface-visibility: hidden;
    z-index: 1000;

    &--sort {
      cursor: pointer;
    }

    .el-checkbox__label {
      color: var(--c-nuetral--600);
    }

    & > * {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .app-table__header-divider {
    grid-column: 1 / -1;
  }

  *[data-type='divider'] {
    position: sticky;
    top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--c-nuetral--600);
    height: 48px;
    background-color: var(--c-nuetral--200);
    border-bottom: 1px solid var(--c-nuetral--300);
    grid-column: 1 / -1;
    z-index: 1000;
  }

  .app-table__header-item,
  .app-table__cell,
  .app-table__row-expand-arrow {
    display: flex;
    align-items: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    &:not(.app-table__row-expand),
    &:not(.app-table__row-expand) > * {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    &:not([data-type='divider'], .app-table__row-expand) {
      height: 48px;
      padding: var(--cell-padding);
      // transition: background-color 0.13s ease-in;
    }

    &--no-padding {
      --cell-padding: 0;
    }
  }

  .app-table__header-item > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .app-table__row {
    &:not(&--expanded) > *:not([data-type='divider'], .app-table__row-expand) {
      border-bottom: 1px solid var(--c-nuetral--300);
    }

    .app-table__row-expand {
      grid-column: 1 / -1;
      cursor: auto;
    }

    .el-checkbox {
      z-index: 0 !important;
    }
  }

  .app-table__row {
    .app-table__cell-content-wrapper--tooltip {
      height: 100%;
      background-color: red;
      width: 4px;

      * {
        height: 100%;
      }

      .app-tooltip__slot-wrapper {
        display: none;
      }
    }

    &:hover {
      .app-tooltip__slot-wrapper {
        display: block;
      }
    }

    &:hover > *:not(.app-table__row-expand, .app-table__cell-content-wrapper--tooltip, .app-table__cell-tooltip) {
      background-color: var(--c-nuetral--300);
    }

    &:active > *:not(.app-table__row-expand, .app-table__cell-tooltip) {
      background-color: rgba(222, 233, 235, 0.3);
    }
  }
}
</style>
