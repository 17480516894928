<script setup>
import { getCurrentInstance } from 'vue';
import { ElConfigProvider } from 'element-plus';
import TheGlobalDrawer from '@/components/TheGlobalDrawer';
import TheDialogProvider from './components/TheDialogProvider.vue';

import ru from 'element-plus/dist/locale/ru';
const size = 'default';

const globalStore = useGlobalStore();

globalStore.topProgress = getCurrentInstance();
</script>

<template>
  <el-config-provider
    :size="size"
    :locale="ru">
    <vue-progress-bar />

    <router-view v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
        appear>
        <component :is="Component" />
      </transition>
    </router-view>

    <div id="app-dropdown-items" />
    <div id="app-tooltip-items" />
    <div id="app-dialog-wrapper" />
    <div id="app-drawer-wrapper" />

    <the-global-drawer />

    <TheDialogProvider />
  </el-config-provider>
</template>

<style>
#app {
  font-weight: normal;
  height: 100vh;
  overflow: hidden;
}

#app-dropdown-items {
  position: absolute;
  top: 0;
  z-index: 9999;
}

#app-tooltip-items {
  position: absolute;
  top: 0;
  z-index: 9999;
}
</style>
