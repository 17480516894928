import { MaybeRef } from 'vue';
import { createPopper, Instance, Placement } from '@popperjs/core';

export type UsePopperPlacement = Placement;

interface UsePopperOptions {
  placement: UsePopperPlacement;
  offset: MaybeRef<[number, number]> | ComputedRef<[number, number]>;
}

export function usePopper(targetRef: Ref<Element>, containerRef: Ref<HTMLElement>, options: UsePopperOptions) {
  const popperInstance = ref<Instance>(null);

  const currentPlacement = ref<UsePopperPlacement>(options.placement);

  const initializePopper = async () => {
    if (popperInstance.value) return;

    await nextTick();

    popperInstance.value = createPopper(targetRef.value, containerRef.value, {
      placement: options.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: options.offset,
          },
        },

        {
          name: 'arrow',
          options: {
            padding: ({ popper, placement, reference }) => {
              currentPlacement.value = placement;

              return popper.width / reference.width;
            },
          },
        },
      ],
    });

    popperInstance.value.update();
  };

  const isVisible = ref(false);
  const isVisibleContent = ref(false);

  const setPopperEventListeners = (enabled: boolean) => {
    popperInstance.value?.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled }],
    }));
  };

  const enablePopperEventListeners = () => setPopperEventListeners(true);
  const disablePopperEventListeners = () => setPopperEventListeners(false);

  watch(isVisible, async (value) => {
    if (value) {
      await initializePopper();
      enablePopperEventListeners();
    } else {
      disablePopperEventListeners();
    }

    isVisibleContent.value = value;
  });

  const close = () => {
    if (!isVisible.value) {
      return;
    }

    isVisible.value = false;
  };

  const open = () => {
    if (isVisible.value) {
      return;
    }

    isVisible.value = true;
  };

  return {
    isVisible,
    close,
    open,
    currentPlacement,
  };
}
