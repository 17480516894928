import { AxiosRequestConfig } from 'axios';
import router from '../router';
import { Api } from './api';

// old
const API_URL = `${import.meta.env.VITE_HTTP_PROTOCOL}${import.meta.env.VITE_BACKEND_HOSTNAME}/web`;

// new
const BASE_ULR = `${import.meta.env.VITE_HTTP_PROTOCOL}${import.meta.env.VITE_BACKEND_HOSTNAME}`;

console.log('🔹 api url:', API_URL);

const client = new Api({
  baseURL: BASE_ULR,
  securityWorker: (accessToken) => (accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}),
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

client.instance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();

    if (authStore.accessToken) {
      config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

//@todo нормально типизировать error
client.instance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    console.log('interceptors.response', error);
    const authStore = useAuthStore();

    const ERR_CANCELED = 'ERR_CANCELED';

    const originalConfig = error.code === ERR_CANCELED ? error : error.config;

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const rs: { data: { accessToken: string; refreshToken: string; deviceId: string } } = await client.instance.post('/token/refresh', {
            accessToken: authStore.accessToken,
            refreshToken: authStore.refreshToken,
            deviceId: authStore.getDeviceId(),
          });

          const { accessToken, refreshToken } = rs.data;

          console.log('🍉 TRY REFRESH TOKEN, RESPONSE:', rs);
          authStore.setTokens({ accessToken, refreshToken });

          console.log('🍉 TRY REFRESH TOKEN, LOCAL STORE:', { 'access-token': authStore.accessToken, 'refresh-token': authStore.refreshToken });

          originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            client.instance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          refreshAndRetryQueue.length = 0;

          return client.instance(originalConfig);
        } catch (refreshError) {
          console.log('🍉 TRY REFRESH TOKEN, ERROR:', refreshError);

          authStore.removeTokens();

          router.push({ name: 'auth-login' });
          throw refreshError;
          // return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalConfig, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

export default client;
