import { loadImport } from './helpers';
import { AppRouteRecord } from '.';

export const authRoutes = [
  {
    name: 'auth',
    path: '/auth',
    component: () => loadImport('../pages/PageAuth/PageAuth.vue'),
    redirect: { name: 'auth-login' },
    children: [
      {
        name: 'auth-login',
        path: '/login',
        component: () => loadImport('../pages/PageAuth/components/AuthLogin.vue'),
      },
      {
        name: 'auth-terms',
        path: '/terms',
        component: () => loadImport('../pages/PageAuth/components/AuthTerms.vue'),
      },
      {
        name: 'auth-forgot-password',
        path: '/forgot-password',
        component: () => loadImport('../pages/PageAuth/components/AuthForgotPassword.vue'),
      },
      {
        name: 'auth-password-create',
        path: '/password-create',
        component: () => loadImport('../pages/PageAuth/components/AuthPasswordCreate.vue'),
      },
      {
        name: 'auth-forgot-login',
        path: '/forgot-login',
        component: () => loadImport('../pages/PageAuth/components/AuthForgotLogin.vue'),
      },
    ],
  },
] as const satisfies readonly AppRouteRecord[];
