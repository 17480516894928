<template>
  <div
    class="app-divider flex gap-3 items-center"
    :class="{ 'flex-col': direction === 'vertical' }">
    <div
      v-if="contentPosition !== 'left'"
      class="app-divider__line" />

    <slot />
    <div
      v-if="contentPosition === 'left' || (contentPosition === 'center' && slots.default)"
      class="app-divider__line" />
  </div>
</template>

<script setup>
const props = defineProps({
  contentPosition: {
    type: String,
    default: 'center',
  },

  margin: {
    type: String,
    default: null,
  },

  direction: {
    type: String,
    default: 'horizontal',
  },
});

const slots = useSlots();

const marginComputed = computed(() => {
  if (props.direction === 'horizontal') {
    return `${props.margin} 0 ${props.margin} 0`;
  } else if (props.direction === 'vertical') {
    return `0 ${props.margin} 0 ${props.margin}`;
  }
});

const lineWidthComputed = computed(() => {
  if (props.direction === 'horizontal') {
    return '100%';
  } else if (props.direction === 'vertical') {
    return '1px';
  }
});

const lineHeightComputed = computed(() => {
  if (props.direction === 'horizontal') {
    return '1px';
  } else if (props.direction === 'vertical') {
    return '100%';
  }
});
</script>

<style lang="scss" scoped>
.app-divider {
  width: auto;
  // height: 100%;
  color: var(--c-nuetral--600);
  margin: v-bind(marginComputed);
  font-size: 14px;
  line-height: 20px;
}

.app-divider__line {
  height: v-bind(lineHeightComputed);
  width: v-bind(lineWidthComputed);
  background-color: var(--c-nuetral--300);
}
</style>
