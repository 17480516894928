import axios from 'axios';

const API_URL = `${import.meta.env.VITE_HTTP_PROTOCOL}${import.meta.env.VITE_BACKEND_HOSTNAME}/integration`;

const instance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();

    const DEV_API_KEY = authStore.Api1CToken;

    config.headers['Api-Key'] = DEV_API_KEY;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
