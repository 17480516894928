import type { Props, Emits } from '@/ui/AppDialog';
import { Component, CreateComponentPublicInstance, DefineComponent } from 'vue';

type ResolveEmits<T extends Record<string, readonly any[]>> = {
  [K in keyof T as K extends string ? `on${Capitalize<K>}` : never]?: (...args: T[K]) => void;
};

type UseDialogParams = Omit<Props & ResolveEmits<Emits>, 'onClose'>;

type GetUseDialogArgs<T extends string | DefineComponent<{}, {}, any>> = T extends string
  ? [params: UseDialogParams & { confirm: () => any }]
  : T extends CreateComponentPublicInstance<infer P, any, any, any, any, infer E>
  ? [params: UseDialogParams, componentParams: P & E]
  : never;

type DialogsList = {
  component: string | Component;
  params: UseDialogParams;
  componentParams?: Record<string, any>;
};

export const dialogsList = ref<DialogsList[]>([]);

export async function useDialog<T extends string | DefineComponent<{}, {}, any>>(content: T, ...args: GetUseDialogArgs<T>) {
  dialogsList.value.push({
    component: typeof content === 'string' ? h('div', { innerHTML: content }) : markRaw(content),
    params: args[0],
    componentParams: args[1] ?? {},
  });
}
