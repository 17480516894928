export const SidebarDisplayTypeModel = {
  DEFAULT: 'default',
  DETAIL: 'detail',
} as const;

export const SidebarCategoryEnum = {
  Management: {
    id: 1,
    value: 'Management',
    name: 'Управление',
  },
  Cash: {
    id: 2,
    value: 'Cash',
    name: 'Касса',
  },
  Checkpoint: {
    id: 3,
    value: 'Checkpoint',
    name: 'КПП',
  },
  Service: {
    id: 4,
    value: 'Service',
    name: 'Обслуживание',
  },
} as const;

export const SidebarCategoryMenuItemsEnum = {
  Management: {
    id: 1,
    menuItems: [
      {
        routeName: 'villages',
        icon: 'villages',
        title: 'Посёлки',
      },
      {
        routeName: 'persons',
        icon: 'persons',
        title: 'Лица',
      },
      {
        routeName: 'organizations',
        icon: 'organizations',
        title: 'Организации',
        soon: true,
      },
      {
        routeName: 'products',
        icon: 'products',
        title: 'Продукты',
        soon: true,
      },
      {
        routeName: 'integrations',
        icon: 'integrations',
        title: 'Интеграции',
        soon: true,
      },
      {
        icon: 'notifications',
        title: 'Уведомления',
        soon: true,
      },
      {
        icon: 'analytics',
        title: 'Аналитика',
        soon: true,
      },
    ],
  },
  Checkpoint: {
    id: 2,
    menuItems: [
      {
        routeName: 'dispatcher',
        icon: 'dispatcher',
        title: 'Диспетчерская',
        soon: true,
      },
      {
        routeName: 'checkpoints',
        icon: 'checkpoints',
        title: 'КПП',
        soon: true,
      },
      {
        routeName: 'travel-events',
        icon: 'travel-events',
        title: 'События',
        soon: true,
      },
      {
        routeName: 'passes',
        icon: 'passes',
        title: 'Пропуска',
        soon: true,
      },
      {
        routeName: 'vehicles',
        icon: 'vehicles',
        title: 'Авто',
        soon: true,
      },
      {
        icon: 'analytics',
        title: 'Аналитика',
        soon: true,
      },
    ],
  },
  Cash: {
    id: 3,
    menuItems: [
      {
        routeName: 'contract-templates',
        icon: 'contracts',
        title: 'Лицевые счета',
        soon: true,
      },
      {
        routeName: 'accruals',
        icon: 'accruals',
        title: 'Начисления',
        soon: true,
      },
      {
        routeName: 'refills',
        icon: 'refills',
        title: 'Поступления',
        soon: true,
      },
      {
        routeName: 'products',
        icon: 'products',
        title: 'Продукты',
        soon: true,
      },
      {
        icon: 'analytics',
        title: 'Аналитика',
        soon: true,
      },
    ],
  },
  Service: {
    id: 4,
    menuItems: [
      {
        icon: 'appeals',
        title: 'Обращения',
        soon: true,
      },
      {
        icon: 'votes',
        title: 'Голосования',
        soon: true,
      },
      {
        icon: 'orders',
        title: 'Заказы',
        soon: true,
      },
      {
        icon: 'analytics',
        title: 'Аналитика',
        soon: true,
      },
    ],
  },
} as const;
