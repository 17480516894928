<template>
  <el-select
    :model-value="modelValue"
    :remote-method="searchHandler"
    :value-key="valueKey"
    reserve-keyword
    filterable
    clearable
    remote
    collapse-tags-tooltip
    remote-show-suffix
    class="app-select-scroll"
    @focus="focusHandler"
    @visible-change="popperIsVisible = $event"
    @update:model-value="$emit('update:model-value', $event)">
    <template #prefix>
      <div class="app-select-scroll__prefix">
        <Transition name="fade">
          <div
            v-if="isLoadingSpinnerVisible"
            v-loading="isLoading && isSelectTouched"
            class="app-select-scroll__prefix-loading"></div>
        </Transition>

        <div
          class="app-select-scroll__prefix-slot"
          :class="{ 'app-select-scroll__prefix-slot--loading': isLoading && isSelectTouched }">
          <slot
            v-if="$slots.prefix"
            name="prefix" />

          <app-icon
            v-else
            name="icons/search"
            color="var(--c-nuetral--600)"
            size="16px"></app-icon>
        </div>
      </div>
    </template>

    <div ref="wrapperRef">
      <slot :data="storeListComputed" />
    </div>
  </el-select>
</template>

<script setup>
import { useFilter } from '@/composition/useFilter';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  modelValue: { type: [Array, String, Number, null, Object], required: true },
  handler: { type: Function, required: true },
  handlerParams: { type: [Object, null], default: () => ({}) },
  queryParams: { type: Object, default: () => ({}) },
  searchKey: { type: String, default: 'searchByName' },
  useMountedFetch: { type: Boolean, default: false },
  valueKey: { type: String, required: false },
  onEmptyResult: { type: Function, default: () => {} },
  mapper: { type: Function, default: null },
});

const emit = defineEmits(['update:model-value']);

const storeList = ref([]);

const popperIsVisible = ref(false);

const wrapperRef = ref(null);

const isSelectTouched = ref(false);
const isLoadingSpinnerVisible = computed(() => {
  return isLoading && isSelectTouched && !storeListComputed.length;
});

const { pagination, changePagination, isLoading, changeRawFilter } = useFilter(fetchHandler, {
  handlerParams: toRef(props, 'handlerParams'),
  queryParams: { ...props.queryParams, [props.searchKey]: null },
})

async function fetchHandler(...args) {
  const {
    data: { items, metaData },
  } = await props.handler(...args);

  storeList.value = [...storeList.value, ...items];
  return { data: { items, metaData } };
}

// watch(
//   () => props.customFilter,
//   (value) => changeRawFilter(value)
// );

const loadingSearchService = ref(null);
const stopIntersectionWatch = watch(
  () => [storeList.value, wrapperRef.value, popperIsVisible.value],
  async () => {
    if (!storeList.value || !wrapperRef.value || !popperIsVisible.value) return;

    const popperEl = wrapperRef.value.closest('.el-select-dropdown');
    const lastItem = wrapperRef.value.children[wrapperRef.value.children.length - 1];

    if (lastItem) {
      const { stop } = useIntersectionObserver(
        toRaw(lastItem),
        async ([{ isIntersecting }]) => {
          if (isIntersecting) {
            stop();

            if (pagination.hasNext) {
              loadingSearchService.value?.close();

              loadingSearchService.value = ElLoading.service({
                target: wrapperRef.value.closest('.el-select-dropdown'),
                background: 'rgba(255, 255, 255, 0.7)',
              });

              try {
                await changePagination({ pageNumber: pagination.currentPage + 1 });
              } catch (e) {
              } finally {
                loadingSearchService.value?.close();
              }
            }
          }
        },
        { root: popperEl }
      );
    }
  },
  { flush: 'post' }
);

onErrorCaptured(() => {
  if (stopIntersectionWatch) {
    stopIntersectionWatch();
  }
});

let lastQuery;
watch(
  () => props.handlerParams,
  () => {
    lastQuery = false;
  }
);

async function searchHandler(query) {
  if (lastQuery === query) return;

  lastQuery = query;

  loadingSearchService.value?.close();

  loadingSearchService.value = ElLoading.service({
    target: wrapperRef.value.closest('.el-select-dropdown'),
    background: 'rgba(255, 255, 255, 0.7)',
  });

  try {
    storeList.value = [];
    // filter[props.searchKey] = query;

    await changeRawFilter(props.searchKey, query);
    // await manualChangeParams['searchTerm'](query);

    if (!storeList.value.length) {
      props.onEmptyResult();
    }
  } catch (error) {
  } finally {
    loadingSearchService.value.close();
  }
}

async function focusHandler(e) {
  isSelectTouched.value = true;

  if (!e.relatedTarget?.classList.value.includes('el-popper')) {
    searchHandler(null);
  }
}

const mapper = props.mapper;
const storeListComputed = computed(() => {
  if (!props.modelValue) return storeList.value;

  const list = mapper ? mapper(storeList.value) : storeList.value;

  if (typeof props.modelValue === 'object' && !Array.isArray(props.modelValue) && props.valueKey && props.modelValue[props.valueKey]) {
    return [props.modelValue].concat(list.filter((item) => item[props.valueKey] !== props.modelValue[props.valueKey]));
  } else if (Array.isArray(props.modelValue) && props.valueKey) {
    const keys = props.modelValue.map((item) => item[props.valueKey]);

    return [...props.modelValue, ...list.filter((item) => !keys.includes(item[props.valueKey]))];
  }

  return list;
});
</script>

<style lang="scss" scoped>
.app-select-scroll {
  --el-loading-spinner-size: 20px;

  :deep(.el-input__prefix-inner > :last-child) {
    margin-right: 8px;
    width: max-content;
  }

  :deep(.el-select-tags-wrapper.has-prefix) {
    // margin-left: 36px;
  }

  :deep(input::placeholder) {
    transition: opacity 0.23s ease-in-out;
  }
}

.app-select-scroll__prefix {
  height: 100%;
  display: flex;
  align-items: center;
}

.app-select-scroll__prefix-slot {
  transition:
    transform 0.13s ease-in-out,
    opacity 0.13s ease-in-out;
  transition-delay: 0.13s;

  &--loading {
    transform: scale(0);
    opacity: 0;
  }

  .app-select-scroll__prefix-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    transition: all 0.13s ease-in-out;
  }
}
</style>
