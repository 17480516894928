<template>
  <div
    class="app-radio-select"
    :class="{ '--segments': segments }">
    <slot-render-fn></slot-render-fn>
  </div>
</template>

<script lang="ts" setup>
import { RadioSelectInjectionKey } from './constants';
import { RadioModelValue } from './types';

interface Props {
  modelValue: RadioModelValue;
  segments?: boolean;
}

interface Emits {
  (e: 'update:model-value', value: RadioModelValue): void;
}

const props = withDefaults(defineProps<Props>(), {
  segments: false,
});

const emit = defineEmits<Emits>();

const slots = useSlots();
let slotRenderFn: Slot | VNode = slots.default;

provide(
  RadioSelectInjectionKey,
  computed({
    get() {
      return props.modelValue;
    },

    set(value) {
      if (props.modelValue !== value) {
        emit('update:model-value', value);
      }
    },
  })
);
</script>

<style lang="scss" scoped>
.app-radio-select {
  display: inline-flex;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 12px;
  height: 40px;
  border-radius: 24px;

  &.--segments {
    gap: 0;
    background-color: var(--c-nuetral--300);
    padding: 2px;
  }
}
</style>
