<template>
  <div
    class="app-menu-button-item"
    :class="{ '--disabled': disabled, '--loading': loading }"
    @click.prevent.stop="!disabled && !loading ? clickHandler($event) : null">
    <Transition
      name="zoom-fade-local"
      mode="out-in"
      appear>
      <AppIcon
        v-if="loading"
        size="16px"
        name="icons/spinner-loading" />

      <AppIcon
        v-else-if="icon"
        :name="icon"
        :source="iconSource"
        :color="iconColor"
        :rotate="iconRotate"
        size="16px" />
    </Transition>

    <div class="text-primary">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { AppDropdownRefInjectionKey } from './helpers';

interface Props {
  icon: GlobalIconsNames;
  iconSource?: boolean;
  iconColor?: string;
  iconRotate?: string;
  disabled?: boolean;
  loading?: boolean;
}

interface Emits {
  (e: 'click', $event: MouseEvent);
}

withDefaults(defineProps<Props>(), {
  iconSource: false,
  iconColor: 'currentColor',
  iconRotate: '0deg',
  disabled: false,
  loading: false,
});

const emit = defineEmits<Emits>();

const AppDropdownRef = inject(AppDropdownRefInjectionKey);

function clickHandler($event: MouseEvent) {
  emit('click', $event);
  AppDropdownRef.value.close();
}

onMounted(() => {
  if (!AppDropdownRef.value) {
    throw new Error('Компонент AppMenuButtonItem можно использовать только внутри AppMenuButton');
  }
});
</script>

<style lang="scss" scoped>
.app-menu-button-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 0 16px;
  transition:
    background-color 0.13s ease-in,
    opacity 0.13s ease-in;
  cursor: pointer;

  &:hover {
    background-color: var(--c-nuetral--300);
  }

  &:active {
    background-color: var(--c-nuetral--400);
  }

  &.--disabled,
  &.--loading {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.zoom-fade-local-enter-from,
.zoom-fade-local-leave-to {
  transform: scale(0.6);
  opacity: 0;
}
</style>
