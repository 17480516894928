<template>
  <div
    class="app-tag"
    :class="[`--${type}`, { '--icon-right': iconRight || !slots.default, '--icon-left': iconLeft || !slots.default }]">
    <AppIcon
      v-if="iconLeft"
      size="16px"
      :source="iconLeftSource"
      :name="iconLeft"
      :rotate="iconLeftRotate" />

    <slot />

    <AppIcon
      v-if="iconRight"
      size="16px"
      :source="iconRightSource"
      :name="iconRight"
      :rotate="iconRightRotate" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  type?: 'default' | 'alert' | 'warning' | 'success' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'grey';
  iconLeft?: GlobalIconsNames;
  iconRight?: GlobalIconsNames;
  iconLeftRotate?: string;
  iconRightRotate?: string;
  iconLeftSource?: boolean;
  iconRightSource?: boolean;
}

withDefaults(defineProps<Props>(), {
  type: 'default',
  iconLeft: null,
  iconRight: null,
  iconLeftRotate: '0deg',
  iconRightRotate: '0deg',
  iconLeftSource: false,
  iconRightSource: false,
});

const slots = useSlots();
</script>

<style lang="scss" scoped>
.app-tag {
  display: flex;
  gap: 4px;

  width: fit-content;
  padding: 4px 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  border-radius: 24px;

  &.--icon-right {
    padding-right: 4px;
  }

  &.--icon-left {
    padding-left: 4px;
  }

  &.--default {
    background-color: var(--c-nuetral--300);
    color: var(--c-primary--700);
  }

  &.--alert {
    background-color: var(--c-alert--100);
    color: var(--c-alert--700);
  }

  &.--warning {
    background-color: var(--c-warning--100);
    color: var(--c-warning--700);
  }

  &.--success {
    background-color: var(--c-success--100);
    color: var(--c-success--700);
  }

  &.--accent-1 {
    background-color: var(--c-accent-1--100);
    color: var(--c-accent-1--700);
  }

  &.--accent-2 {
    background-color: var(--c-accent-2--100);
    color: var(--c-accent-2--700);
  }

  &.--accent-3 {
    background-color: var(--c-accent-3--100);
    color: var(--c-accent-3--700);
  }

  &.--accent-4 {
    background-color: var(--c-accent-4--100);
    color: var(--c-accent-4--700);
  }

  &.--grey {
    background-color: var(--c-nuetral--400);
    color: var(--c-nuetral--600);
  }
}
</style>
