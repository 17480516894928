function useNestIndexFactory() {
  let nestIndexesStack = []

  return {
    lastValue: () => nestIndexesStack.at(-1),
    add(val: number) {
      nestIndexesStack.push(val)
      console.log('Запушил нест индекс', nestIndexesStack);
    },
    remove() {
      nestIndexesStack.splice(nestIndexesStack.length - 1, 1)
      console.log('Удалил нест индекс', nestIndexesStack);
    }
  }
}

export const useNestIndex = useNestIndexFactory()