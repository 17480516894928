import { defineStore } from 'pinia';
import { ComponentInternalInstance } from 'vue';

export const useGlobalStore = defineStore('global', () => {
  const topProgress: Ref<ComponentInternalInstance> = ref(null);
  const isMainContentLoading = ref(false);

  function topProgressStart() {
    topProgress.value.appContext.config.globalProperties.$Progress.start();
  }

  function topProgressFinish() {
    topProgress.value.appContext.config.globalProperties.$Progress.finish();
  }

  async function handleMainContentLoading(handler: () => any) {
    isMainContentLoading.value = true;
    await handler();
    isMainContentLoading.value = false;
  }

  return { isMainContentLoading, topProgress, topProgressStart, topProgressFinish, handleMainContentLoading };
});
