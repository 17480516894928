<template>
  <slot />
</template>

<script lang="ts" setup>
import { RadioInjectionKey } from './constants';
import { RadioModelValue } from './types';

interface Props {
  modelValue: RadioModelValue;
  disabled?: boolean;
}

interface Emits {
  (e: 'update:model-value', value: RadioModelValue): void;
  (e: 'change', value: RadioModelValue): void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});
const emit = defineEmits<Emits>();

provide(RadioInjectionKey, {
  modelValue: computed({
    get() {
      return props.modelValue;
    },

    set(value) {
      emit('update:model-value', value);
      emit('change', value);
    },
  }),
  globalDisabled: computed(() => props.disabled),
});
</script>
