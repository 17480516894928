<template>
  <div
    class="app-radio-item flex gap-2 items-center"
    :class="{ '--disabled': globalDisabled || disabled }"
    @click="changeValue">
    <svg
      height="16"
      width="16">
      <circle
        class="app-radio-item__main-circle"
        cx="8"
        cy="8"
        r="7"
        stroke-width="1" />

      <circle
        class="app-radio-item__dot"
        cx="8"
        cy="8"
        r="4.5"
        :opacity="isActive ? '1' : '0'" />
    </svg>

    <slot />
  </div>

  <input
    type="radio"
    :disabled="globalDisabled || disabled"
    :checked="isActive"
    :id="id"
    hidden
    @click="changeValue" />
</template>

<script lang="ts" setup>
import { RadioInjectionKey } from './constants';
import { RadioModelValue } from './types';

interface Props {
  value: RadioModelValue;
  disabled?: boolean;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  id: 'default-radio-id',
});

const { modelValue, globalDisabled } = inject(RadioInjectionKey);

const isActive = computed(() => {
  return modelValue.value === props.value;
});

const changeValue = () => {
  globalDisabled.value || props.disabled || isActive.value ? null : (modelValue.value = props.value);
};

const hoverBGColorComputed = computed(() => {
  return isActive.value || globalDisabled.value || props.disabled ? 'var(--c-nuetral--200)' : 'var(--c-nuetral--400)';
});
</script>

<style lang="scss" scoped>
.app-radio-item {
  width: fit-content;
  cursor: pointer;

  &:hover {
    .app-radio-item__main-circle {
      fill: v-bind(hoverBGColorComputed);
    }
  }

  &.--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.app-radio-item__main-circle {
  transition: fill 0.13s ease-in;
  stroke: var(--c-primary--500);
  fill: var(--c-nuetral--200);
}

.app-radio-item__dot {
  transition: opacity 0.13s ease-in;
  fill: var(--c-primary--500);
}
</style>
