<template>
  <div
    v-if="items.length"
    class="app-filter-preview">
    <div
      class="flex items-center"
      style="align-self: flex-start; flex-shrink: 0">
      <div
        class="flex items-center text-secondary text-grey"
        style="height: 24px; align-self: baseline">
        {{ title }}
      </div>
    </div>

    <div class="app-filter-preview__items-wrap">
      <div
        v-for="item in items"
        class="app-filter-preview__item">
        <div class="text-secondary">{{ item.label }}</div>

        <AppIcon
          name="icons/cross"
          color="var(--c-nuetral--100)"
          size="16px"
          class="app-filter-preview__item-close"
          @click="removeFilter(item)" />
      </div>
    </div>

    <AppCloseButton
      type="text"
      icon-only="icons/cross"
      style="margin-left: auto; margin-right: 8px; align-self: baseline"
      @click="emit('clear')">
    </AppCloseButton>

    <!-- <div class="app-filter-preview__item text-secondary">10 руб.</div>
      <div class="app-filter-preview__item text-secondary">от 10 до 30</div>
      <div class="app-filter-preview__item text-secondary">c 10:16</div>
      <div class="app-filter-preview__item text-secondary">c 10:16 по 20:30</div> -->
  </div>
</template>

<script lang="ts" setup generic="T extends Record<string, any>">
interface Props {
  title?: string;
  filter: T;
  templateItems: { [Key in keyof T]?: string };
  excludeItems?: Array<keyof T>;
  defaultValues: T;
}

interface Emits {
  <P extends keyof T>(e: 'remove', key: P, value: T[P]): void;
  (e: 'clear'): void;
}

const props = withDefaults(defineProps<Props>(), { title: 'Выбранные фильтры:' });

const emit = defineEmits<Emits>();

const items = computed(() => {
  const keys = props.templateItems;

  const keysExclude = ['pageNumber', 'pageSize', 'searchByName', 'searchByNumber'];
  const keyExcludeWithContains = ['sort'];

  return Object.entries(props.filter)
    .filter(
      ([key, value]) =>
        !keysExclude.includes(key) && (Array.isArray(value) ? !!value.length : true) && !props.excludeItems?.includes(key) && keyExcludeWithContains.every((item) => !key.includes(item)) && !!value
    )
    .map(([key, value]) => {
      return {
        label: keys[key],
        source: key,
        value,
      };
    })
    .flat();
});

function removeFilter(previewItem: (typeof items)['value'][number]) {
  emit('remove', previewItem.source, props.defaultValues[previewItem.source]);
}
</script>

<style lang="scss" scoped>
.app-filter-preview {
  display: flex;
  align-items: center;
  gap: 8px;
}

.app-filter-preview__items-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.app-filter-preview__item {
  display: flex;
  height: 24px;
  padding: 2px 4px 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 24px;
  background-color: var(--c-nuetral--800);

  color: var(--c-nuetral--100);
}

.app-filter-preview__item-close {
  transition:
    opacity 0.13s ease-in,
    transform 0.13s ease-in;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.4;
    transform: scale(0.8);
  }
}
</style>
