import { AllowedComponentProps, VNodeProps } from 'vue';
import { defineStore } from 'pinia';
import drawersData from '@/components/TheGlobalDrawer/drawers';

type DrawersData = typeof drawersData;
type DrawersDataKeys = keyof DrawersData;

type DrawerDataRaw<T extends DrawersDataKeys = DrawersDataKeys> = {
  name: T;
  params: Omit<InstanceType<DrawersData[T]>['$props'], keyof VNodeProps | keyof AllowedComponentProps>;
};

type DrawerDataWithCompoent = {
  component: DrawersData[DrawersDataKeys];
  params: Omit<InstanceType<DrawersData[DrawersDataKeys]>['$props'], keyof VNodeProps | keyof AllowedComponentProps>;
};

export const useDrawerStore = defineStore('drawer', () => {
  const route = useRoute();

  const sidebarStore = useSidebarStore();

  const isOpen = ref(false);
  const isLoading = ref(false);

  const lastDrawer = ref<DrawerDataRaw | null>(null);
  const lastDrawerFromName = ref<string | null>(null);
  const lastDrawerToName = ref<string | null>(null);

  const drawersStack = ref<DrawerDataWithCompoent[]>([]);

  const currentDrawer = computed<DrawerDataWithCompoent | null>(() => {
    return drawersStack.value.length ? drawersStack.value[drawersStack.value.length - 1] : null;
  });

  const sidebarTypeOnOpen = ref<boolean | null>(null);

  function openDrawer<T extends DrawersDataKeys>({ name, params }: DrawerDataRaw<T>, f = true) {
    isLoading.value = true;
    isOpen.value = true;
    sidebarTypeOnOpen.value = sidebarStore.isCompact;

    drawersStack.value.push({ component: drawersData[name], params });

    localStorage.setItem('drawer-data', JSON.stringify({ name, params }));
    f ? localStorage.setItem('drawer-data-route', route.name as string) : null;

    lastDrawer.value = { name, params };
  }

  function closeDrawer() {
    isOpen.value = false;
    isLoading.value = false;
    sidebarStore.isCompact = sidebarTypeOnOpen.value;
    sidebarTypeOnOpen.value = null;
    drawersStack.value = [];
    localStorage.removeItem('drawer-data');
    localStorage.removeItem('drawer-data-route');
  }

  function goBack() {
    if (!isLoading.value) {
      isLoading.value = true;
      drawersStack.value.splice(-1, 1);
    }
  }

  function openLastDrawer() {
    if (lastDrawer.value) {
      openDrawer(lastDrawer.value);
    }
  }

  return {
    isOpen,
    isLoading,
    currentDrawer,
    openDrawer,
    closeDrawer,
    goBack,
    openLastDrawer,
    drawersStack,
    lastDrawerFromName,
    lastDrawerToName,
  };
});
