<template>
  <div class="flex flex-col">
    <div
      v-for="item in sidebarStore.detailRoutesData"
      :key="item.routeName"
      class="sidebar__menu-item"
      :class="[
        {
          'sidebar__menu-item--active': $route.name === item.routeName,
          'sidebar__menu-item--soon': item.soon,
          'sidebar__menu-item--errors': item.hasErrors,
        },
        `sidebar__menu-item--${item.routeName}`,
      ]"
      @click="item.soon ? null : $router.replace({ name: item.routeName, query: item.routeQuery || {} })">
      <div class="sidebar__menu-item_border" />

      <div class="sidebar__menu-item_title">
        <div
          v-if="item.hasErrors"
          class="sidebar__menu-item_error-badge" />
        {{ item.title }}
      </div>

      <div
        v-if="item.soon"
        class="sidebar__menu-item_soon-badge">
        Скоро
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const sidebarStore = useSidebarStore();
</script>

<style lang="scss" scoped>
.sidebar__menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0 10px 24px;
  height: 43px;
  color: var(--c-nuetral--500);
  cursor: pointer;
  transition:
    color var(--transition-value),
    background-color var(--transition-value);

  &--active {
    color: var(--c-nuetral--100);

    .sidebar__menu-item_border {
      opacity: 1;
      transform: scale(1) translateX(0);
    }

    .sidebar__menu-item_title {
      font-weight: 500;
      color: var(--c-success--900);
    }
  }

  &--soon {
    .sidebar__menu-item_title {
      opacity: 0.3;
    }

    &:hover {
      .sidebar__menu-item_soon-badge {
        width: 50px;
        height: 20px;
        color: white;
      }
    }
  }

  &:hover:not(&--active):not(&--soon) {
    color: var(--c-nuetral--100);
  }
}

.sidebar__menu-item_border {
  position: absolute;
  left: 0;
  opacity: 0;
  background-color: var(--c-nuetral--100);
  width: 4px;
  height: 43px;
  z-index: 1;
  transform: scale(0.4) translateX(-16px);
  transition:
    opacity var(--transition-value),
    transform var(--transition-value);
}

.sidebar__menu-item_title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sidebar__menu-item_error-badge {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: var(--c-alert--500);
}
</style>
