import { AppRouteRecord } from '.';
import { loadImport } from './helpers';

export const villagesRoutes = [
  {
    name: 'villages',
    path: 'villages',
    component: () => loadImport('../pages/PageVillages/PageVillages.vue'),
    meta: {
      requiresAuth: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
