import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import PageMain from '@/pages/PageMain.vue';

import { authRoutes } from './auth';
import { startRoutes } from './start';
import { devRoutes } from './dev';
import { villagesRoutes } from './villages';
import { personsRoutes } from './persons';
import { landRoutes } from './land';
import { landOwnershipsRoutes } from './land-ownerships';

export type AppRouteRecord = Omit<RouteRecordRaw, 'name' | 'children'> & {
  name: string;
  children?: readonly AppRouteRecord[];
};

const routes = [
  {
    path: '/',
    name: 'root',
    component: PageMain,
    redirect: { name: 'start' },
    children: [...startRoutes, ...devRoutes, ...villagesRoutes, ...personsRoutes, ...landRoutes, ...landOwnershipsRoutes],
  },
  ...authRoutes,
] as const satisfies readonly AppRouteRecord[];

type GetRouteData<T extends AppRouteRecord> = T extends { children: readonly AppRouteRecord[] }
  ? { name: T['name']; params: { [Key in ExtractRouteParams<T['path']>]: string } } | GetRoutesData<T['children']>
  : { name: T['name']; params: { [Key in ExtractRouteParams<T['path']>]: string } };

type ExtractRouteParamsDeep<T extends string> = T extends `${infer Any}:${infer Param}/${infer Rest}` ? Param : T extends `${infer Any}:${infer Param}` ? Param : never;

type ExtractRouteParams<T extends string> = T extends `${infer _Start}/${infer Any}:${infer Param}/${infer Rest}`
  ? Param | ExtractRouteParamsDeep<Rest>
  : T extends `${infer _Start}/:${infer Param}`
  ? Param
  : never;
type GetRoutesData<T extends readonly AppRouteRecord[]> = GetRouteData<T[number]>;
type Routes = typeof routes;
export type RouteData = GetRoutesData<Routes>;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as unknown as RouteRecordRaw[],
});

router.beforeEach((to, from, next) => {
  const isAuthorized = !!localStorage.getItem('access-token');
  const toSomeAuthPage = to.matched.some((route) => (route.name as string).includes('auth'));

  if (isAuthorized && toSomeAuthPage) {
    return next({ name: 'start' });
  } else if (!isAuthorized && !toSomeAuthPage) {
    if (to.matched.some((route) => (route.name as string).includes('public-pass'))) {
      return next();
    }

    return next({ name: 'auth-login' });
  }

  next();
});

router.afterEach((to, from) => {
  const drawerStore = useDrawerStore();

  if (!!router.options.history.state.openDrawerOnBackLink && !drawerStore.lastDrawerFromName && !drawerStore.lastDrawerToName) {
    drawerStore.lastDrawerFromName = from.name;
    drawerStore.lastDrawerToName = to.matched[1]?.name || to.matched[0].name;
  }

  if (drawerStore.lastDrawerFromName === to.name) {
    drawerStore.lastDrawerFromName = null;
    drawerStore.lastDrawerToName = null;

    setTimeout(() => {
      drawerStore.openLastDrawer();
      // routerStore.setRouteState({ openDrawerOnBackLink: false })
    }, 0);
  } else if (to.matched.every((item) => item.name !== drawerStore.lastDrawerToName)) {
    drawerStore.lastDrawerFromName = null;
    drawerStore.lastDrawerToName = null;
    // routerStore.setRouteState({ openDrawerOnBackLink: false })
  }

  const sidebarStore = useSidebarStore();

  if (!!to.meta.isPageDetail === true) {
    if (from.name) {
      if (to.matched.at(-2).name !== from.matched.at(-2).name || (to.matched.length === 2 && from.matched.length === 2)) {
        if (!(!!to.meta.isPageDetail === true && !!from.meta.isPageDetail === true && to.name === sidebarStore.backlinkRoutesStack.at(-1).name)) {
          sidebarStore.backlinkRoutesStack.push({ name: from.name, params: from.params, query: from.query });
        }
      }
    }
  }

  if (!!to.meta.isPageDetail === false) {
    sidebarStore.backlinkRoutesStack = [];
  }

  if (to.name === sidebarStore.backlinkRoutesStack.at(-1)?.name) {
    sidebarStore.backlinkRoutesStack.pop();
  }
});

router.beforeResolve((to, from, next) => {
  next();
});

export default router;
