<template>
  <slot />
</template>

<script lang="ts" setup>
import { CheckboxValue } from './types';
import { CheckboxInjectionKey } from './constants';

interface Props {
  modelValue: Array<CheckboxValue>;
  disabled?: boolean;
}

interface Emits {
  (e: 'update:model-value', value: Array<CheckboxValue>);
  (e: 'change', value: Array<CheckboxValue>);
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits<Emits>();

function emitUpdateModelValue(value: CheckboxValue) {
  if (props.modelValue.includes(value)) {
    emit(
      'update:model-value',
      props.modelValue.filter((el) => el !== value)
    );
  } else {
    emit('update:model-value', [...props.modelValue, value]);
  }
}

watch(
  () => props.modelValue,
  () => {
    emit('change', props.modelValue);
  }
);

provide(CheckboxInjectionKey, { globalDisabled: props.disabled, isCheckboxGroup: true, groupModelValue: computed(() => props.modelValue), emitUpdateModelValue });
</script>
