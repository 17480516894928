import { useDebounceFn } from '@vueuse/core';

export function useFilter(handler, options) {
  const optionsWithDefaultValues = {
    onMountedFetch: true,
    ...options,
  };

  const currentPagination = reactive({
    currentPage: 1,
    totalPages: 1,
    pageSize: 20,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });

  const data = ref([]);

  const rawFilter = reactive({
    ...optionsWithDefaultValues.queryParams,
  });

  const handlerPagination = reactive({
    pageNumber: 1,
    pageSize: 20,
  });

  const rawFilterWithPagination = computed(() => ({
    ...handlerPagination,
    ...rawFilter,
  }));

  const reactiveFilter = reactive(
    Object.fromEntries(
      Object.keys({ ...rawFilter }).map((key) => [
        key,
        computed({
          get() {
            return rawFilter[key];
          },
          async set(value) {
            rawFilter[key] = value;

            handlerPagination.pageNumber = 1;

            if (value?.length && key.includes('search')) {
              await debouncedRequest();
            } else {
              await fetch();
            }
          },
        }),
      ])
    )
  );

  const isLoading = ref(false);
  const isFilterEnabled = ref(false);
  const isEmpty = ref(false);

  const handlerArgs = computed(() => {
    let result = [];

    if (optionsWithDefaultValues.handlerParams) {
      result.push(toValue(optionsWithDefaultValues.handlerParams));
    }

    const parsedFilter = { ...rawFilterWithPagination.value, ...toValue(optionsWithDefaultValues.staticQueryParams) };

    Object.keys(parsedFilter).forEach((key) => {
      if (Array.isArray(parsedFilter[key])) {
        parsedFilter[key] = parsedFilter[key].length ? parsedFilter[key].join(',') : null;
      } else if (key.includes('sort')) {
        parsedFilter[key] = parsedFilter[key] ? 'Ascending' : 'Descending';
      }
    });

    result.push(parsedFilter);
    
    return result;
  });

  function checkFilterEnabled() {
    const keysOfExclude = ['pageNumber', 'pageSize', ...(optionsWithDefaultValues.staticQueryParams ? Object.keys(optionsWithDefaultValues.staticQueryParams) : [])];

    return Object.keys(rawFilter)
      .filter((key) => !keysOfExclude.includes(key))
      .some((key) => {
        if (Array.isArray(rawFilter[key])) {
          return !!rawFilter[key].length;
        } else {
          return !!rawFilter[key];
        }
      });
  }

  let controller;

  async function fetch() {
    try {
      isLoading.value = true;

      if (controller) {
        controller.abort();
      }

      controller = new AbortController();
      const signal = controller.signal;

      const { data: responseData } = await handler(...handlerArgs.value, { signal });

      data.value = responseData.items ?? responseData;
      Object.assign(currentPagination, responseData.metaData ?? {});

      isFilterEnabled.value = checkFilterEnabled();
      isEmpty.value = currentPagination.totalPages === 0;

      isLoading.value = false;

      return responseData;
    } catch (e) {
      throw e;
    }
  }

  const SEARCH_DELAY = 600;
  const debouncedRequest = useDebounceFn(fetch, SEARCH_DELAY);

  async function changePagination({ pageNumber, pageSize }) {
    handlerPagination.pageNumber = pageNumber ?? currentPagination.currentPage;
    handlerPagination.pageSize = pageSize ?? currentPagination.pageSize;

    await fetch();
  }

  if (optionsWithDefaultValues.staticQueryParams) {
    watch(
      optionsWithDefaultValues.staticQueryParams,
      () => {
        changePagination({ pageNumber: 1 });
      },
      { deep: true }
    );
  }



  if (optionsWithDefaultValues.onMountedFetch) {
    onMounted(async () => {
      await fetch();
    });
  }

  const clearRawFilter = reactive(JSON.parse(JSON.stringify(rawFilter)));

  async function resetFilter() {
    Object.assign(rawFilter, clearRawFilter);

    await fetch();
  }

  async function resetFilterByKeys(keys = []) {
    const clearFilterByKeys = Object.fromEntries(Object.entries(clearRawFilter).filter((el) => keys.includes(el[0])));
    Object.assign(rawFilter, clearFilterByKeys);

    await fetch();
  }

  async function changeRawFilter(key, value) {
    handlerPagination.pageNumber = 1;
    rawFilter[key] = value;

    await fetch();
  }

  async function changeRawFilterMany(newFilter) {
    handlerPagination.pageNumber = 1;

    Object.assign(rawFilter, newFilter);

    await fetch();
  }

  return {
    data,

    isLoading,
    isFilterEnabled,
    isEmpty,

    changeRawFilterMany,
    changeRawFilter,
    resetFilter,
    resetFilterByKeys,
    clearRawFilter: readonly(clearRawFilter),
    filter: reactiveFilter,

    changePagination,
    fetch,
    pagination: readonly(currentPagination),
  };
}
