<template>
  <div class="flex items-center justify-end gap-1">
    <AppButton
      :type="cancelButtonConfigWithDefaults.type"
      @click="emit('close')">
      {{ cancelButtonConfigWithDefaults.title }}
    </AppButton>

    <AppTooltip
      :content="tooltip"
      :disabled="!confirmDisabled">
      <AppButton
        :type="confirmButtonConfigWithDefaults.type"
        :disabled="confirmDisabled"
        @click="emit('confirm')">
        {{ confirmButtonConfigWithDefaults.title }}
      </AppButton>
    </AppTooltip>
  </div>
</template>

<script lang="ts" setup>
import { ActionButtonsConfig } from './helpers';

type Props = {
  confirmButtonConfig?: ActionButtonsConfig;
  cancelButtonConfig?: ActionButtonsConfig;
  confirmDisabled?: boolean;
  tooltip?: string;
};

const props = defineProps<Props>();

const confirmButtonConfigWithDefaults = ref<ActionButtonsConfig>({
  title: 'Подтвердить',
  type: 'primary',
  ...props.confirmButtonConfig,
});
const cancelButtonConfigWithDefaults = ref<ActionButtonsConfig>({
  title: 'Отмена',
  type: 'text',
  ...props.cancelButtonConfig,
});

type Emits = {
  confirm: [];
  close: [];
};

const emit = defineEmits<Emits>();
</script>

<style lang="scss" scoped></style>
