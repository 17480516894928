import { AppRouteRecord } from '.';
import { loadImport } from './helpers';

export const devRoutes = [
  {
    name: 'dev',
    path: 'dev',
    component: () => loadImport('../pages/PageDev/PageDev.vue'),
  },
] as const satisfies readonly AppRouteRecord[];
