<template>
  <AppDrawer
    :model-value="drawerStore.isOpen"
    :width="drawerWidth"
    @update:model-value="drawerStore.closeDrawer"
    @back="drawerStore.goBack">
    <div class="drawer-content">
      <component
        :is="drawerStore.currentDrawer.component"
        v-bind="{ ...drawerStore.currentDrawer.params, ...$attrs }" />
    </div>
  </AppDrawer>
</template>

<script lang="ts" setup>
const route = useRoute();

const drawerStore = useDrawerStore();
const sidebarStore = useSidebarStore();

const isFirstRouting = ref(true);

const drawerWidth = computed(() => {
  return `calc(100vw - ${sidebarStore.isCompact ? '64px' : '224px'})`;
});

watch(
  () => route.name,
  () => {
    if (drawerStore.isOpen && localStorage.getItem('drawer-data-route') !== route.name) {
      drawerStore.closeDrawer();
    }

    if (isFirstRouting.value) {
      if (localStorage.getItem('drawer-data') && localStorage.getItem('drawer-data-route') === route.name) {
        const drawerData = JSON.parse(localStorage.getItem('drawer-data'));
        drawerStore.openDrawer(drawerData, false);
      }

      if (localStorage.getItem('drawer-data-route') !== route.name) {
        localStorage.removeItem('drawer-data');
        localStorage.removeItem('drawer-data-route');
      }

      isFirstRouting.value = false;
    }
  }
);
</script>

<style lang="scss">
.drawer-content {
  width: 100%;
  height: 100%;
}
</style>
