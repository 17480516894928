import { errorNotification } from '@/helpers';
import { AsyncComponentLoader, Component } from 'vue';

const importList: Record<string, AsyncComponentLoader<Component>> = import.meta.glob('../../pages/**/*.vue');

//@todo Понять как возвращать нормальные данные
export const loadImport = (path: string) => {
  const resolvedPath = '../' + path;

  return new Promise(async (resolve) => {
    const globalStore = useGlobalStore();

    globalStore.topProgressStart();

    try {
      const component = await importList[resolvedPath]();

      resolve(component);
    } catch (e) {
      console.error(e)
      errorNotification(`Произошла ошибка при загрузке компонента: ${e}`);
    } finally {
      globalStore.topProgressFinish();
    }
  });
};
