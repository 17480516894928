<template>
  <slotRenderFn />
</template>

<script setup>
const slots = useSlots();

let slotRenderArray = [];
let slotRenderFn = h('div', { class: 'flex' }, slotRenderArray);

onBeforeMount(() => {
  slots.default().forEach((el, idx) => {
    slotRenderArray.push(h('div', { style: `margin-left:${idx === 0 ? 0 : -8}px` }, el));
  });
});
</script>

<style lang="scss" scoped></style>
