function getZIndexCounter(startIndex: number) {
  let zIndexCounter = useCounterRef(startIndex)

  function useCounterRef(value) {
    return customRef((track, trigger) => {
      return {
        get() {
          value += 1
          console.log('Взял индекс', value);

          track()
          return value
        },
        set(newValue) {
          value = newValue
          trigger()
        }
      }
    })
  }


  return zIndexCounter

}

export const useZIndex = getZIndexCounter(1000)