<template>
  <div class="app-dialog">
    <Transition
      name="fade"
      mode="out-in"
      appear>
      <div
        class="app-dialog__overlay"
        @click="closeOnClickOverlay ? $emit('close') : null">
        <div
          v-loading="isLoading"
          class="app-dialog__window"
          @click.stop>
          <div class="flex items-center justify-between">
            <div class="text-header-1">
              {{ title }}
            </div>

            <AppCloseButton @click="$emit('close')" />
          </div>

          <div class="app-dialog__window-content scrollbar-default text-primary">
            <slot
              :confirm="confirm"
              :close="close" />
          </div>

          <!-- <div
            v-if="!hideFooter"
            class="flex items-center justify-end gap-1">
            <AppButton
              :type="cancelButtonConfigWithDefaults.type"
              @click="$emit('close')">
              {{ cancelButtonConfigWithDefaults.title }}
            </AppButton>

            <AppButton
              :type="confirmButtonConfigWithDefaults.type"
              @click="confirm">
              {{ confirmButtonConfigWithDefaults.title }}
            </AppButton>
          </div> -->

          <AppDialogFooter
            v-if="!hideFooter"
            :cancel-button-config="cancelButtonConfigWithDefaults"
            :confirm-button-config="confirmButtonConfigWithDefaults"
            @close="close"
            @confirm="confirm" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { useNestIndex } from '@/composition/useNestIndex';
import { useZIndex } from '@/composition/useZIndex';
import { onKeyStroke } from '@vueuse/core';
import type { Emits, Props, ActionButtonsConfig } from './helpers';

const props = withDefaults(defineProps<Props>(), {
  bgColor: 'var(--c-nuetral--300)',
  closeOnClickOverlay: true,
  closeOnPressEsc: true,
  width: '540px',
  minHeight: 'auto',
  maxHeight: '80vh',
  cancelButtonConfig: () => ({}),
  confirmButtonConfig: () => ({}),
  hideFooter: false,
});

const emit = defineEmits<Emits>();

const confirmButtonConfigWithDefaults = ref<ActionButtonsConfig>({
  title: 'Подтвердить',
  type: 'primary',
  ...props.confirmButtonConfig,
});
const cancelButtonConfigWithDefaults = ref<ActionButtonsConfig>({
  title: 'Отмена',
  type: 'text',
  ...props.cancelButtonConfig,
});

const isLoading = ref(false);
const confirmDisabled = ref(false);
const confirmHandler = ref<() => any>(null);

function close() {
  emit('close');
}

async function confirm(...args) {
  isLoading.value = true;

  try {
    await props.confirm(...args);
  } catch (error) {
    throw error;
  } finally {
    isLoading.value = false;

    emit('close');
  }
}

let currentIndex = useZIndex.value;
useNestIndex.add(currentIndex);

if (props.closeOnPressEsc) {
  onKeyStroke('Escape', () => {
    if (currentIndex === useNestIndex.lastValue()) {
      emit('close');
    }
  });
}

onUnmounted(() => {
  useNestIndex.remove();
  emit('closed');
});
</script>

<style lang="scss" scoped>
.app-dialog__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: v-bind(currentIndex);

  .app-dialog__window {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: v-bind(width);
    min-width: 540px;
    max-width: 896px;
    height: auto;
    min-height: v-bind(minHeight);
    max-height: v-bind(maxHeight);
    padding: 32px;
    border-radius: 24px;
    overflow: hidden;
    background-color: v-bind(bgColor);
    animation: fade 0.13s ease-in-out forwards;

    .app-dialog__window-content {
      overflow: auto;
      margin-right: -22px;
      padding-right: 12px;
      height: 100%;
    }
  }

  @keyframes fade {
    from {
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
