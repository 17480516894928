<template>
  <div
    class="app-table__cell"
    :class="{ '--editable': editable, '--is-editing': isEditing }"
    :style="{ '--cell-padding': padding }">
    <app-button
      v-if="editable && !isEditing"
      type="outline"
      icon-only="icons/edit"
      class="app-table__cell-edit-button"
      @click="isEditing = !isEditing">
    </app-button>

    <app-button
      v-if="editable && isEditing"
      icon-only="icons/plus"
      class="app-table__cell-edit-button"
      @click="isEditing = !isEditing">
    </app-button>

    <div
      class="app-table__cell-content-wrapper flex items-center gap-2"
      :class="{ 'app-table__cell-content-wrapper--tooltip': tooltip }">
      <div class="app-table__cell-slot">
        <slot :is-editing="isEditing"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  min: String,
  max: String,
  item: Object,
  padding: String,
  editable: Boolean,
  tooltip: Boolean,
});

const isEditing = ref(false);
</script>

<style lang="scss" scoped>
.app-table__cell {
  display: flex;
  align-items: center;
  transition: box-shadow 0.13s ease-in;

  &:hover {
    .app-table__cell-edit-button {
      opacity: 1;
    }

    &.--editable {
      box-shadow: inset 0 0 0 1px var(--c-primary--100);
    }

    // .app-table__cell-edit-button + div {
    //   opacity: 0.2;
    // }
  }

  &:not(.--is-editing) .app-table__cell-content-wrapper {
    overflow: hidden;
  }
}

.app-table__cell-slot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-table__cell-edit-button {
  position: absolute;
  right: -40px;
  opacity: 0;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.13s ease-in;
}
</style>
