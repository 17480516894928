<template>
  <template v-if="small">
    <div class="small-pagination flex items-center justify-between">
      <small-button-base
        :disabled="currentPage === 1"
        @click="$emit('change-pagination', currentPage - 1)">
        <app-icon name="icons/arrow2-left" />
      </small-button-base>

      <div>{{ currentPage }} из {{ totalPages }}</div>

      <small-button-base
        :disabled="currentPage === totalPages"
        @click="$emit('change-pagination', currentPage + 1)">
        <app-icon name="icons/arrow2" />
      </small-button-base>
    </div>
  </template>

  <template v-else>
    <div class="flex gap-2 align-items">
      <button-base
        style="order: 1"
        :background="background"
        :disabled="currentPage === 1"
        @click="currentPage === 1 ? null : $emit('change-pagination', currentPage - 1)">
        <app-icon name="icons/arrow2-left" />
      </button-base>

      <button-base
        v-if="!(currentPage < 4) && totalPages > 6"
        style="order: 2; color: rgba(40, 57, 54, 0.3)"
        :background="background"
        @click="$emit('change-pagination', totalPages - 4 < currentPage ? totalPages - 4 : currentPage - 2)">
        <span :style="{ 'text-decoration': background ? null : 'underline' }">...</span>
      </button-base>

      <button-base
        v-for="page in displayedPages"
        :background="background"
        :style="{ order: page === 1 ? '1' : page === totalPages && !(currentPage > totalPages - 3) ? '5' : '3' }"
        :key="page"
        :active="page === currentPage"
        @click="currentPage === page ? null : $emit('change-pagination', page)">
        {{ page }}
      </button-base>

      <button-base
        v-if="!(currentPage > totalPages - 3) && totalPages > 6"
        style="order: 4; color: rgba(40, 57, 54, 0.3)"
        :background="background"
        @click="$emit('change-pagination', currentPage < 4 ? 5 : currentPage + 2)">
        <span :style="{ 'text-decoration': background ? null : 'underline' }">...</span>
      </button-base>

      <button-base
        style="order: 5"
        :background="background"
        :disabled="currentPage === totalPages"
        @click="currentPage === totalPages ? null : $emit('change-pagination', currentPage + 1)">
        <app-icon name="icons/arrow2" />
      </button-base>
    </div>
  </template>
</template>

<script lang="ts" setup>
import ButtonBase from './components/ButtonBase.vue';
import SmallButtonBase from './components/SmallButtonBase.vue';

interface Props {
  currentPage: number;
  totalPages: number;
  background: boolean;
  small?: boolean;
}

interface Emits {
  (e: 'change-pagination', value: number);
}

const props = withDefaults(defineProps<Props>(), {
  totalPages: 1,
  currentPage: 1,
  small: false,
});
defineEmits<Emits>();

const displayedPages = computed(() => {
  if (props.totalPages > 6) {
    if (props.currentPage < 4) {
      return [1, 2, 3, 4, props.totalPages];
    } else if (props.currentPage > props.totalPages - 3) {
      return [1, props.totalPages - 3, props.totalPages - 2, props.totalPages - 1, props.totalPages];
    } else {
      return [1, props.currentPage - 1, props.currentPage, props.currentPage + 1, props.totalPages];
    }
  } else if (props.totalPages > 1) {
    return [...Array.from(Array(props.totalPages).keys())].map((x) => ++x);
  }

  return [1];
});
</script>

<style lang="scss">
.small-pagination {
  max-width: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
</style>
