<template>
  <div class="app-avatar">
    {{ upperCaseFirstLetter(person.lastName && person.middleName ? person.lastName[0] : person.firstName[0]) }}
    <div class="app-avatar__status">
      <AppTooltip
        content="Аккаунт не создан"
        placement="bottom-start"
        :offset="[-12, 6]"
        bg-color="var(--c-nuetral--500)">
        <img :src="`/icons/${getStatusIconName()}.svg`" />
      </AppTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { upperCaseFirstLetter } from '@/helpers';
import { PersonInListDto } from '@/http/api';

interface Props {
  person: PersonInListDto;
}

const props = defineProps<Props>();

function getStatusIconName() {
  if (!props.person.accounts.length) {
    return 'remove-duotone';
  }
  return 'check-ring-duotone--warning';
}
</script>

<style lang="scss" scoped>
.app-avatar {
  width: 32px;
  height: 32px;
  background-color: var(--c-nuetral--400);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-avatar__status {
  position: absolute;
  bottom: -2px;
  right: -2px;
}
</style>
