import { AppRouteRecord } from '.';
import { loadImport } from './helpers';

export const landRoutes = [
  {
    path: 'land-cadastre/:landId',
    name: 'edit-land-cadastre',
    component: () => loadImport('../pages/PageEditLandCadastre/PageEditLandCadastre.vue'),
    meta: {
      requiresAuth: true,
      isPageDetail: true,
    },
  },
] as const satisfies readonly AppRouteRecord[];
