<template>
  <div class="app-tabs-item">
    <slot v-if="tabName === activeTabName"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  name: String,
});

const tabs = inject('tabs');
const activeTabName = inject('active-tab-name');

const tabName = ref(null);

onBeforeMount(() => {
  tabs.value.push({
    title: props.title,
    name: props.name,
  });

  tabName.value = props.name;
});
</script>

<style scoped>
.app-tabs-item {
  display: contents;
}
</style>