<template>
  <div class="app-form-stepper flex justify-between items-center">
    <div class="pager">стр. {{ currentPage || 1 }} из {{ totalPages }}</div>

    <div
      v-if="steps.length > 1"
      class="flex gap-2">
      <app-button
        type="text"
        :disabled="currentPage === 1 || disabled"
        @click="$emit('prev', steps[currentPage - 2])">
        Назад
      </app-button>
      <app-button
        type="primary"
        :disabled="currentPage === totalPages || disabled"
        @click="$emit('next', steps[currentPage])">
        Далее
      </app-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  steps: string[];
  disabled?: boolean;
};

interface Emits {
  (e: 'next', pageName: string);
  (e: 'prev', pageName: string);
}
const route = useRoute();
const props = defineProps<Props>();
defineEmits<Emits>();

const currentPage = computed(() => {
  return props.steps.findIndex((step) => step === route.name) + 1;
});
const totalPages = computed(() => {
  return props.steps.length;
});
</script>

<style lang="scss" scoped>
.app-form-stepper {
  padding: 24px 24px 0 24px;
}

.pager {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--c-nuetral--600);
}
</style>
