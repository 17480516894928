<template>
  <div
    class="main"
    :class="[`main--${appDisplayType}`]">
    <div class="main__bg"></div>

    <TheSidebar
      :display-type="appDisplayType"
      :display-type-model="SidebarDisplayTypeModel" />

    <div
      v-loading="globalStore.isMainContentLoading"
      element-loading-background="rgba(255, 255, 255, 1)"
      class="main__content main-content--bg-white scrollbar-default">
      <!-- <router-view v-slot="{ Component }">
        <transition
          name="fade"
          mode="out-in"
          appear>
          <component :is="Component" />
        </transition>
      </router-view> -->

      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import TheSidebar, { SidebarDisplayTypeModel } from '@/components/TheSidebar';

const route = useRoute();

const globalStore = useGlobalStore();

const appDisplayType = computed(() => {
  if (isPageDetail.value) {
    return SidebarDisplayTypeModel.DETAIL;
  }

  return SidebarDisplayTypeModel.DEFAULT;
});

const isPageDetail = computed(() => {
  return route.matched.some((route) => !!route.meta.isPageDetail);
});
</script>

<style lang="scss" scoped>
.main {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  transition: background-color 0.13s ease-in-out;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

.main__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(224px + 34px);
  height: 100%;
  background: linear-gradient(294deg, #062b22 0%, #0d4e3f 100%);
}

.main--detail .main__content {
  border-radius: 0;
}

.main__content {
  background-color: var(--c-nuetral--300);
  border-radius: 40px 0 0 40px;
  padding: 32px;
  overflow: auto;
  height: 100%;
  max-height: 100%;
  transition:
    background-color 0.13s ease-in-out,
    border-radius 0.13s ease-in-out;
}

.main__content.main-content--bg-grey {
  background-color: var(--c-nuetral--300);
}
</style>
