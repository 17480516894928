<template>
  <div class="dialog-provider">
    <TransitionGroup
      name="fade"
      appear>
      <AppDialog
        v-for="(item, index) in dialogsList"
        v-bind="getPropsFromParams(item.params)"
        :key="index"
        @closed="item.params.onClosed"
        @close="dialogsList.pop()">
        <template #default="{ confirm, close }">
          <Component
            :is="item.component"
            v-bind="getPropsFromParams(item.componentParams)"
            v-on="getEmitsFromParams(item.componentParams)"
            @confirm="confirm"
            @close="close" />
        </template>
      </AppDialog>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { dialogsList } from '@/composition/useDialogNew';

function getEmitsFromParams<T extends Record<string, any>>(params: T) {
  return Object.fromEntries(Object.entries(params).filter((el) => el[0].slice(0, 2) === 'on')) as Pick<T, `on${string}`>;
}

function getPropsFromParams<T extends Record<string, any>>(params: T) {
  return Object.fromEntries(Object.entries(params).filter((el) => el[0].slice(0, 2) !== 'on')) as Omit<T, `on${string}`>;
}
</script>

<style lang="scss" scoped>
.dialog-provider {
  z-index: 1500;
}
</style>
