<template>
  <div
    :ref="(el) => (tooltipTargetRef = (el as Element)?.children?.[0])"
    class="app-tooltip"
    v-on="tooltipTargetEventHandlers">
    <div>
      <slot />
    </div>
  </div>

  <Teleport to="#app-tooltip-items">
    <div ref="tooltipContentRef">
      <Transition
        name="fade"
        appear>
        <div
          v-if="isVisible"
          class="app-tooltip__content-wrapper">
          <div class="app-tooltip__content">
            <slot name="content">
              {{ content }}
            </slot>
          </div>

          <div
            class="triangle"
            data-popper-arrow
            :class="[`${currentPlacement}`]" />
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { usePopper, UsePopperPlacement } from '@/composition/usePopper';

interface Props {
  content?: string;
  placement?: UsePopperPlacement;
  offset?: [number, number];
  disabled?: boolean;
  visible?: boolean;
  maxWidth?: string;
  bgColor?: string;
  textColor?: string;
  targetTransition?: boolean;
}

interface Emits {
  (e: 'update:visible', value: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  offset: () => [0, 6],
  maxWidth: '192px',
  placement: 'bottom',
  bgColor: 'var(--c-nuetral--800)',
  textColor: 'var(--c-nuetral--300)',
});

const emit = defineEmits<Emits>();

const tooltipTargetRef = ref<Element>(null);
const tooltipContentRef = ref<HTMLDivElement>(null);

const { isVisible, currentPlacement } = usePopper(tooltipTargetRef, tooltipContentRef, {
  placement: props.placement,
  offset: ({ placement, reference }) => {
    let result: [number, number] = [0, 6];

    if (placement.includes('start')) {
      result = [-(reference.width / 2), 6];
    }

    if (placement.includes('end')) {
      result = [reference.width + 6, 6];
    }

    return result;
  },
});

const tooltipTargetEventHandlers = computed(() => {
  return {
    ['mouseenter']: () => {
      if (!props.disabled) {
        isVisible.value = true;
      }
    },

    ['mouseleave']: () => {
      if (!props.disabled) {
        isVisible.value = false;
      }
    },
  };
});

watch(
  () => props.visible,
  (value) => (isVisible.value = value),
  { immediate: true }
);
watch(isVisible, (value) => emit('update:visible', value));
</script>

<style lang="scss" scoped>
.app-tooltip {
  display: contents;
}

:deep() {
  .app-icon {
    transition: all 0.13s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      color: var(--c-primary--300);
    }
  }
}

.app-tooltip__content-wrapper {
  --tooltip-bg-color: v-bind(bgColor);
  --tooltip-text-color: v-bind(textColor);

  background-color: var(--tooltip-bg-color);
  padding: 8px 12px;
  border-radius: 12px;
  width: max-content;
  max-width: v-bind(maxWidth);
  pointer-events: none;

  .app-tooltip__content {
    color: var(--tooltip-text-color);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  [class^='triangle top'] {
    border-width: 6px 6px 0 6px;
    border-color: var(--tooltip-bg-color) transparent transparent transparent;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);

    &[class*='end'] {
      border-color: var(--tooltip-bg-color) transparent transparent transparent;
      transform: translateX(0);
      right: 16px;
      left: auto;
    }

    &[class*='start'] {
      border-color: var(--tooltip-bg-color) transparent transparent transparent;
      transform: translateX(0);
      left: 16px;
      right: auto;
    }
  }

  [class^='triangle bottom'] {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent var(--tooltip-bg-color) transparent;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);

    &[class*='end'] {
      border-color: transparent transparent var(--tooltip-bg-color) transparent;
      transform: translateX(0);
      right: 16px;
      left: auto;
    }

    &[class*='start'] {
      border-color: transparent transparent var(--tooltip-bg-color) transparent;
      transform: translateX(0);
      left: 16px;
      right: auto;
    }
  }

  [class^='triangle left'] {
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent var(--tooltip-bg-color);
    right: -5px;
    top: 50%;
    transform: translateY(-50%);

    &[class*='end'] {
      border-color: transparent transparent transparent var(--tooltip-bg-color);
      transform: translateX(0);
      bottom: 16px;
      top: auto;
    }

    &[class*='start'] {
      border-color: transparent transparent transparent var(--tooltip-bg-color);
      transform: translateX(0);
      bottom: auto;
      top: 16px;
    }
  }

  [class^='triangle right'] {
    border-width: 6px 6px 6px 0;
    border-color: transparent var(--tooltip-bg-color) transparent transparent;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);

    &[class*='end'] {
      border-color: transparent var(--tooltip-bg-color) transparent transparent;
      transform: translateX(0);
      bottom: 16px;
      top: auto;
    }

    &[class*='start'] {
      border-color: transparent var(--tooltip-bg-color) transparent transparent;
      transform: translateX(0);
      bottom: auto;
      top: 16px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.03s ease-in;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
