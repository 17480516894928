<template>
  <div
    class="app-alert flex gap-2 items-start"
    :class="[`--${type}`]">
    <app-icon
      v-if="!hideIcon"
      :name="iconName" />

    <div class="flex-2 flex flex-col gap-1">
      <div
        v-if="!hideTitle"
        class="text-header-2">
        {{ titleText }}
      </div>

      <div class="app-alert__content text-primary"><slot>Content</slot></div>

      <app-button
        v-if="!hideLink"
        type="link"
        icon-right="icons/arrow2"
        @click="$emit('link-click')">
        {{ linkText }}
      </app-button>
    </div>

    <app-close-button
      v-if="!hideClose"
      small
      @click="$emit('close-click')" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  type: 'info' | 'alert' | 'warning' | 'success';
  titleText?: string;
  linkText?: string;
  hideLink?: boolean;
  hideIcon?: boolean;
  hideTitle?: boolean;
  hideClose?: boolean;
}

interface Emits {
  (e: 'close-click');
  (e: 'link-click');
}

const props = withDefaults(defineProps<Props>(), {
  type: 'info',
  linkText: 'Link',
  titleText: 'Title',
  hideLink: false,
  hideIcon: false,
  hideTitle: false,
  hideClose: false,
});

defineEmits<Emits>();

const iconName = computed(() => {
  if (props.type === 'info') {
    return 'icons/info-fill';
  }
  if (props.type === 'alert') {
    return 'icons/cross-round-fill';
  }
  if (props.type === 'success') {
    return 'icons/check-round-fill';
  }
  if (props.type === 'warning') {
    return 'icons/attention-fill';
  }
});
</script>

<style lang="scss" scoped>
.app-alert {
  padding: 16px;
  border-radius: 12px;

  &.--info {
    background-color: var(--c-nuetral--300);
    color: var(--c-primary--700);
  }

  &.--alert {
    background-color: var(--c-alert--100);
    color: var(--c-alert--700);
  }

  &.--warning {
    background-color: var(--c-warning--100);
    color: var(--c-warning--700);
  }

  &.--success {
    background-color: var(--c-success--100);
    color: var(--c-success--700);
  }

  .app-alert__content {
    color: var(--c-nuetral--800);
  }
}
</style>
