<template>
  <div class="app-tabs">
    <div
      class="flex justify-between"
      style="padding: var(--tabs-header-padding)">
      <div class="app-tabs__header">
        <div
          v-if="title"
          class="app-tabs__header-title">
          {{ title }}
        </div>

        <div
          v-for="(item, index) in tabs"
          ref="tabItemsRef"
          :data-name="item.name"
          class="app-tabs__header-item"
          :class="{ 'app-tabs__header-item--active': item.name === modelValue }"
          @click="setActiveTab(item.name)">
          {{ item.title }}
        </div>

        <div
          ref="tabItemBorderRef"
          class="app-tabs__header-item-border"></div>
      </div>
      <slot name="header-after"></slot>
    </div>

    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },

  title: String,
});

const emit = defineEmits(['update:model-value']);

const tabs = ref([]);

provide('tabs', tabs);
provide(
  'active-tab-name',
  computed(() => props.modelValue)
);

const tabItemsRef = ref([]);
const tabItemBorderRef = ref(null);

watch(
  [tabs.value, () => props.modelValue],
  () => {
    if (tabs.value.length) {
      setTimeout(() => {
        setActiveBorder(props.modelValue);
      }, 0);
    }
  },
  { immediate: true, flush: 'post' }
);

async function setActiveBorder(name) {
  const target = tabItemsRef.value.find((item) => item.dataset.name === name);

  if (target) {
    const rect = target.getBoundingClientRect();

    requestAnimationFrame(() => {
      tabItemBorderRef.value.style.transform = `translateX(${target.offsetLeft}px)`;
      tabItemBorderRef.value.style.width = `${rect.width}px`;
    });
  }
}

function setActiveTab(name) {
  emit('update:model-value', name);
}

let resizeObserver
onMounted(() => {
  resizeObserver = new ResizeObserver(function () {
    setActiveBorder(props.modelValue);
  });

  resizeObserver.observe(tabItemsRef.value[0]);
});

onBeforeUnmount(() => {
  resizeObserver.unobserve(tabItemsRef.value[0])
  resizeObserver.disconnect()
})
</script>

<style lang="scss" scoped>
.app-tabs {
  --tabs-header-padding: 0 24px;

  display: contents;

  .app-tabs__header {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: max-content;
    border-bottom: 2px solid var(--light-neutral-400, #e1eae8);

    .app-tabs__header-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 125% */
    }

    .app-tabs__header-item {
      display: flex;
      padding: 10px 0px 9px;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
      transition: color 0.13s ease-in-out;

      &:hover {
        color: var(--c-primary--400);
      }

      &--active {
        color: var(--c-primary--500);
      }
    }

    .app-tabs__header-item-border {
      position: absolute;
      bottom: -2px;
      height: 2px;
      width: 50px;
      background-color: var(--c-primary--500);
      transition: transform 0.13s ease-in-out, width 0.13s ease-in-out;
    }
  }
}
</style>
